import { styled } from "styled-components";
import Color from "../../assets/color/color";
// Sec-Main
import MainArrowRightIconDesk from "../../assets/image/section-main/arrow_right_icon.svg";
import MainBg from "../../assets/image/section-main/main_bg.svg";
import MainBgDesk from "../../assets/image/section-main/main_bg_desk.png";
import MainISOImg from "../../assets/image/section-main/iso_down_icon.svg";
import MainAndImg from "../../assets/image/section-main/android_down_icon.svg";
import MainISOImgDesk from "../../assets/image/section-main/iso_down_icon_desk.svg";
import MainAndImgDesk from "../../assets/image/section-main/android_down_icon_desk.svg";
// Sec-Partner
import ArrowDownIcon from "../../assets/image/section-partner/arrow_down_icon.svg";
import ArrowUpIcon from "../../assets/image/section-partner/arrow_up_icon.svg";
// Sec-Result
// ~AwardsBox
import AwardsIcon from "../../assets/image/section-result/awards_icon.svg";
import AwardsIconDesk from "../../assets/image/section-result/awards_icon_desk.svg";
import AwardsLogo from "../../assets/image/section-result/awards_logo.svg";
import AwardsLogoDesk from "../../assets/image/section-result/awards_logo_desk.svg";
// ~Patent
import PatentIcon from "../../assets/image/section-result/patent_icon.svg";
import PatentIconDesk from "../../assets/image/section-result/patent_icon_desk.svg";
import PatentTable from "../../assets/image/section-result/patent_table.svg";
import PatentTableEffet from "../../assets/image/section-result/patent_table_effect.svg";
// ~Transaction
import TransactionIcon from "../../assets/image/section-result/transaction_icon.svg";
import TransactionIconDesk from "../../assets/image/section-result/transaction_icon_desk.svg";
import RedUpIcon from "../../assets/image/section-result/arrow_up_red_icon.svg";
import RedUpIconDesk from "../../assets/image/section-result/arrow_up_red_icon_desk.svg";
// ~Certification
import CertificationIcon from "../../assets/image/section-result/certification_icon.svg";
import CertificationIconDesk from "../../assets/image/section-result/certification_icon_desk.svg";
import Certificationlogo1 from "../../assets/image/section-result/certification_logo1.svg";
import Certificationlogo1Desk from "../../assets/image/section-result/certification_logo1_desk.svg";
import Certificationlogo2 from "../../assets/image/section-result/certification_logo2.svg";
import Certificationlogo2Desk from "../../assets/image/section-result/certification_logo2_desk.svg";
import Certificationlogo3 from "../../assets/image/section-result/certification_logo3.svg";
import Certificationlogo3Desk from "../../assets/image/section-result/certification_logo3_desk.svg";
// ~Setisfaction
//import SetisfactionIcon from "../../assets/image/section-result/satisfaction_icon.svg";
//import SetisfactionIconDesk from "../../assets/image/section-result/satisfaction_icon_desk.svg";
import SetisfactionPercentIcon from "../../assets/image/section-result/satisfaction_current_percent.svg";
import SetisfactionPercentIconDesk from "../../assets/image/section-result/satisfaction_current_percent_desk.svg";
// Sec-MiddleComent
import MiddleComentIcon from "../../assets/image/section-middlecoment/sign_icon.svg";
import MiddleComentIconDesk from "../../assets/image/section-middlecoment/sign_icon_desk.svg";
// Sec-ImplementPoint
import ImplementIcon1 from "../../assets/image/section-implementpoint/implement_list1_icon.svg";
import ImplementIcon1Desk from "../../assets/image/section-implementpoint/implement_list1_icon_desk.svg";
import ImplementIcon2 from "../../assets/image/section-implementpoint/implement_list2_icon.svg";
import ImplementIcon2Desk from "../../assets/image/section-implementpoint/implement_list2_icon_desk.svg";
import ImplementIcon3 from "../../assets/image/section-implementpoint/implement_list3_icon.svg";
import ImplementIcon3Desk from "../../assets/image/section-implementpoint/implement_list3_icon_desk.svg";
// Sec-CustomerInterview
import QuotesLeftIconDesk from "../../assets/image/section-customerinterview/quotes_left_icon.svg";
import QuotesRightIconDesk from "../../assets/image/section-customerinterview/quotes_right_icon.svg";
// Sec-KioskLocation
import LocationIcon from "../../assets/image/section-kiosklocation/location_icon.svg";
import LocationIconDesk from "../../assets/image/section-kiosklocation/location_icon_desk.svg";
import KioskImg from "../../assets/image/section-kiosklocation/kiosk_img.svg";
import KioskImgDesk from "../../assets/image/section-kiosklocation/kiosk_img_desk.svg";
import ArrowRightIcon from "../../assets/image/section-kiosklocation/arrow_right_icon.svg";
import ArrowRightIconDesk from "../../assets/image/section-kiosklocation/arrow_right_icon_desk.svg";
// Sec-AppAD
import ISOImg from "../../assets/image/section-appad/iso_img.svg";
import ISOImgDesk from "../../assets/image/section-appad/iso_img_desk.svg";
import AndroidImg from "../../assets/image/section-appad/android_img.svg";
import AndroidImgDesk from "../../assets/image/section-appad/android_img_desk.svg";
//SideNav
import SideNavIcon from "../../assets/image/sidenav/sidenav-icon.svg";
// Fix-BTN
import ArrowTopIcon from "../../assets/image/fixbtn/arrow_top_icon.svg";
import ArrowTopIconDesk from "../../assets/image/fixbtn/arrow_top_icon_desk.svg";
import ImplementIcon from "../../assets/image/fixbtn/implement_icon.svg";
import ImplementIconDesk from "../../assets/image/fixbtn/implement_icon_desk.svg";




// Main
export const Main = styled.section`

  background-image: url(${MainBg});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  .main-content {
    width: 100%;
    height: 100vh;
    padding-top: 84px;
    padding-bottom: 288px;
  }
  .main-content .main-inner {
    display: flex;
    flex-direction: column;
    align-items: center;    
  }

  .main-content .intro1 {
    display: block;
    font-size: 26px;
    font-weight: 500;
  }

  .main-content h1 {
    font-size: 26px;
    font-weight: 700;
    line-height: 1.3;
    text-align: center;
    margin-bottom: 10px;
  }

  .main-content .intro2 {
    display: block;
    font-size: 20px;
    margin-bottom: 14px;
    font-weight: 500;
  }

  .main-content .download-box {
    display: flex;
    gap: 9px;
    margin-bottom: 12px;
  }

  .main-content .download-box .main-iso-download {
    width: 77px;
    height: 26px;
    background-image: url(${MainISOImg});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }

  .main-content .download-box .main-android-download {
    width: 86px;
    height: 26px;
    background-image: url(${MainAndImg});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }

  .main-content .introduction-button {
    width: 172px;
    height: 42px;
    border-radius: 5px;
    background-color: ${Color.Yellow};
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
    cursor: pointer;            
  }

  .main-content .introduction-button p {
    display: block;    
    font-size: 18px;
    font-weight: 700;
    line-height: 42px;
    color: ${Color.White};
    white-space:nowrap;    
  }

  /* >= 768px (Desktop) */
  @media screen and (min-width: 768px) {
    background-image: url(${MainBgDesk});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    /* margin-top: 96px; */
    /* height: calc(100vh - 96px); */
    /* width: 1920px; */
    /* height: 100vh; */

    .main-content {
      position: relative;
      width: 100%;
      height: 100vh;
      padding: 298px 0 424px 0;
    }

    .main-content .main-inner {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
      width: 400px;
      align-items: start;
    }

    .main-content .intro1 {
      font-size: 44px;
      font-weight: 500;
    }

    .main-content .intro1_ko {
      font-size: 44px;
      font-weight: 500;
    }

    .main-content .intro1_en {
      font-size: 32px;
      font-weight: 500;
    }

    .main-content .intro1_jp {
      font-size: 32px;
      font-weight: 500;
    }

    .main-content .intro1_cn {
      font-size: 44px;
      font-weight: 500;
    }

    .main-content .intro1_ct {
      font-size: 44px;
      font-weight: 500;
    }

    .main-content .intro1_h1 {      
    }
    .main-content .intro1_h1_jp {
      font-size: 50px;
    }

    


    .main-content h1 {
      font-size: 54px;
      font-weight: 700;
      margin-bottom: 32px;
    }

    .main-content .intro2 {
      font-size: 40px;
      font-weight: 500;
      margin-bottom: 32px;
    }

    .main-content .intro2_jp {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 32px;
    }



    .main-content .download-box {
      display: flex;
      gap: 14px;
      margin-bottom: 20px;
    }

    .main-content .download-box .main-iso-download {
      width: 183px;
      height: 61px;
      background-image: url(${MainISOImgDesk});
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }

    .main-content .download-box .main-android-download {
      width: 203px;
      height: 61px;
      background-image: url(${MainAndImgDesk});
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }

    .main-content .introduction-button {
      width: 400px;
      height: 80px;      
    }

    .main-content .introduction-button p {
      position: relative;
      margin: 0 auto;
      width: 220px;
      font-size: 28px;
      font-weight: 700;      
      text-align:left;
    }

    
    .main-content .introduction-button_ko p {
      position: relative;
      margin: 0 auto;
      width: 230px;
      font-size: 28px;
      font-weight: 700;           
      text-align:left;
    }

    .main-content .introduction-button_en p {
      position: relative;
      margin: 0 auto;
      width: 270px;
      font-size: 28px;
      font-weight: 700;            
      text-align:left;
    }

    .main-content .introduction-button_jp p {
      position: relative;
      margin: 0 auto;
      width: 290px;
      font-size: 28px;
      font-weight: 700;            
      text-align:left;
    }

    .main-content .introduction-button_cn p {
      position: relative;
      margin: 0 auto;
      width: 160px;
      font-size: 28px;
      font-weight: 700;            
      text-align:left;
    }

    .main-content .introduction-button_ct p {
      position: relative;
      margin: 0 auto;
      width: 160px;
      font-size: 28px;
      font-weight: 700;            
      text-align:left;
    }


    .main-content .introduction-button p::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(0, -50%);
      display: block;
      width: 27px;
      height: 25px;
      background-image: url(${MainArrowRightIconDesk});
      background-repeat: no-repeat;
      background-size: contain;     
      
    }
  }
`;

// Partner
export const Partner = styled.section`
  background-color: ${Color.lightGray};

  .partner-content {
    width: 100%;
    height: auto;
    padding: 40px 0 20px 0;
    display: flex;
    flex-direction: column;
  }

  .partner-content .partner-intro {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }

  .partner-content .partner-intro .title {
    margin-bottom: 4px;
  }

  /* .partner-content .partner-list-box */
  .partner-content .partner-list-box-mobile {
    display: block;
  }

  .partner-content .partner-list-box-mobile .partner-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    height: ${(props) => (props.isPartnerBTN ? "auto" : "200px")};
  }

  .partner-content .partner-list-box-mobile .partner-list figure {
    width: 100%;
    height: 60px;
    border-radius: 5px;
    border: 1px solid #f4f4f4;
    background: ${Color.White};
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .partner-content .partner-list-box-mobile .partner-list img {
    width: 80%;
    height: 60%;
  }

  .partner-content .partner-list-box-mobile .partner-list .partner-plus {
    width: ${(props) => (props.isPartnerBTN ? "100%" : "0")};
    height: ${(props) => (props.isPartnerBTN ? "60px" : "0")};
  }

  .partner-content .partner-list-box-mobile .partner-list .partner-plus figure {
    border: ${(props) => (props.isPartnerBTN ? "1px solid #f4f4f4" : "none")};
  }

  .partner-content .partner-plus-button {
    width: 30px;
    height: 10px;
    margin: 0 auto;
    margin-top: 20px;
    background-image: url(${(props) =>
      props.isPartnerBTN ? ArrowUpIcon : ArrowDownIcon});
    background-repeat: no-repeat;
    background-size: contain;
  }

  .partner-content .partner-list-box-pc {
    display: none;
  }

  /* >= 768px (Desktop) */
  @media screen and (min-width: 768px) {
    .partner-content {
      padding: 200px 0 200px 0;
    }

    .partner-content .partner-intro {
      margin-bottom: 88px;
    }

    .partner-content .partner-intro .title {
      margin-bottom: 12px;
    }

    /* .partner-content .partner-list-box */
    .partner-content .partner-list-box-mobile {
      display: none;
    }

    .partner-content .partner-plus-button {
      display: none;
    }

    .partner-content .partner-list-box-pc {
      display: block;
      transform: translate(0px, 0px);
      opacity: 1;
      overflow: hidden;
    }

    .partner-content .partner-list-box-pc > div {
      animation: partnerSlide1 20s infinite linear;
      display: flex;
      justify-content: flex-start;
      white-space: nowrap;
    }

    .partner-content .partner-list-box-pc > div.partner-slide2 {
      animation: partnerSlide2 20s infinite linear;
      margin-top: 50px;
      animation-direction: reverse;
    }

    .partner-content .partner-list-box-pc > div.partner-slide3 {
      animation: partnerSlide3 20s infinite linear;
      margin-top: 50px;
    }

    .partner-content .partner-list-box-pc ul {
      display: flex;
      gap: 20px;
    }

    .partner-content .partner-list-box-pc figure {
      width: 337px;
      height: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      border: 1px solid ${Color.Gray};
      background-color: ${Color.White};
    }

    .partner-content .partner-list-box-pc figure img {
      width: 80%;
      height: 60%;
    }

    @keyframes partnerSlide1 {
      0% {
        transform: translateX(0%);
      }
      100% {
        transform: translateX(-2605px);
      }
    }
    @keyframes partnerSlide2 {
      0% {
        transform: translateX(0%);
      }
      100% {
        transform: translateX(-2605px);
      }
    }
    @keyframes partnerSlide3 {
      0% {
        transform: translateX(0%);
      }
      100% {
        transform: translateX(-2300px);
      }
    }
  }
`;

// Result
export const Result = styled.section`
  background-color: ${Color.warmWhite};

  .result-content {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 40px 0;
  }

  .result-content .result-intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
  }

  .result-content .result-intro .title {
    margin-bottom: 4px;
  }

  .result-content .result-intro .desc {
    width: 206px;
  }

  .result-content .result-listbox {
    display: flex;
    flex-direction: column;
    gap: 12px;    
  }

  .result-content .result-listbox li {
    width: 100%;
    border-radius: 10px;
    border: 1px solid ${Color.Gray};
    background: ${Color.White};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    padding: 20px;
    
  }

  .result-content .result-listbox li span {
    color: ${Color.middleBlack};
    font-size: 12px;
    font-weight: 700;    
  }

  .result-content .result-listbox li p {
    color: ${Color.lightBlack};
    font-size: 10px;
    font-weight: 400;    
  }

  .result-content .result-listbox .patent-box .p-padding {    
    height:100%;
  }


  /* awards-box */
  .result-content .result-listbox .awards-box .awards-icon {
    content: url(${AwardsIcon});
  }

  .result-content .result-listbox .awards-box .awards-logo {
    content: url(${AwardsLogo});
    margin-top: 6px;    
  }

  /* patent-box */
  .result-content .result-listbox .patent-box .patent-icon {
    content: url(${PatentIcon});    
  }

  .result-content .result-listbox .patent-box p {
    width: 190px;    
  }

  .result-content .result-listbox .patent-box .patent-table {
    content: url(${PatentTable});
    margin-top: 6px;
  }

  .result-content .result-listbox .patent-box .patent-table-effect {
    content: url(${PatentTableEffet});
  }

  /* transaction-box */
  .result-content .result-listbox .transaction-box .transaction-icon {
    content: url(${TransactionIcon});
    margin-bottom: -4px;
  }

  .result-content .result-listbox .transaction-box .plusdesc {
    display: block;
    width: 200px;
    margin-bottom: 4px;
  }

  .result-content .result-listbox .transaction-box .plusdesc-desk {
    display: none;
  }

  .result-content .result-listbox .transaction-box .last-desc {
    color: ${Color.Black};
    font-size: 8px;
    font-weight: 500;
  }

  .result-content .result-listbox .transaction-box strong {
    color: ${Color.Red};
    font-size: 14px;
    font-weight: 500;
    margin-top: -4px;
  }

  .result-content .result-listbox .transaction-box strong::after {
    content: "";
    width: 9px;
    height: 15px;
    display: inline-block;
    background-image: url(${RedUpIcon});
    margin-left: 4px;
  }

  /* certification-box */
  .result-content .result-listbox .certification-box .certification-icon {
    content: url(${CertificationIcon});
  }

  .result-content .result-listbox .certification-box ul {
    display: flex;
    gap: 30px;
    margin-top: 6px;
  }

  .result-content .result-listbox .certification-box li {
    position: relative;
    border: none;
    padding: 0;
  }

  .result-content .result-listbox .certification-box li:nth-child(1)::after {
    content: "";
    position: absolute;
    top: 60%;
    right: -8px;
    transform: translate(0, -50%);
    width: 1px;
    height: 20px;
    background-color: #e1e1e1;
  }

  .result-content .result-listbox .certification-box li:nth-child(2)::after {
    content: "";
    position: absolute;
    top: 60%;
    right: -20px;
    transform: translate(0, -50%);
    width: 1px;
    height: 20px;
    background-color: #e1e1e1;
  }

  .result-content .result-listbox .certification-box li:nth-child(1) p {
    margin-bottom: 7px;
  }

  .result-content .result-listbox .certification-box li:nth-child(2) p {
    white-space: nowrap;
    margin-bottom: 10px;
  }

  .result-content .result-listbox .certification-box li:nth-child(3) p {
    margin-bottom: 4px;
  }

  .result-content .result-listbox .certification-box .certification-logo1 {
    content: url(${Certificationlogo1});
  }

  .result-content .result-listbox .certification-box .certification-logo2 {
    content: url(${Certificationlogo2});
  }

  .result-content .result-listbox .certification-box .certification-logo3 {
    content: url(${Certificationlogo3});
  }

  /* satisfaction-box */
  .result-content .result-listbox .satisfaction-box .setisfaction-percent-icon {
    content: url(${SetisfactionPercentIcon});
    margin-bottom: 10px;
  }

  .result-content .result-listbox .satisfaction-box .satisfaction-title {
    margin-bottom: 4px;
  }

  .result-content .result-listbox .satisfaction-box .satisfaction-desc {
    margin-bottom: 10px;
  }

  .result-content .result-listbox .satisfaction-box .plusdesc {
    width: 132px;
    height: 30px;
    font-size: 8px;
    padding: 4px 10px;
    background-color: ${Color.lightGray};
    border-radius: 4px;
  }

  /* >= 768px (Desktop) */
  @media screen and (min-width: 768px) {
    .result-content {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      padding: 200px 0;
    }

    .result-content .result-intro {
      margin-bottom: 88px;
    }

    .result-content .result-intro .title {
      width: 100%;
      margin-bottom: 12px;
    }

    .result-content .result-intro .desc {
      width: 100%;
    }

    .result-content .result-listbox {
      display: grid;      
      grid-template-columns: 1fr 1fr 1fr;
      gap: 30px;     
    }

    .result-content .result-listbox li:nth-child(4) {
      grid-column: 1 / 3;
      grid-row: 2 / 2;
    }

    .result-content .result-listbox li {
      
      width: 100%;
      border-radius: 10px;
      border: 1px solid #e1e1e1;
      background: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0;
      padding: 60px 72px;      
    }

    .result-content .result-listbox li span {
      font-size: 32px;
      font-weight: 700;
      
    }

    .result-content .result-listbox li p {      
      font-size: 22px;
      font-weight: 400;      
    }

    /* awards-box */
    .result-content .result-listbox .awards-box .awards-icon {
      content: url(${AwardsIconDesk});
      margin-bottom: 15px;      
    }

    .result-content .result-listbox .awards-box span {
      margin-bottom: 22px;
    }

    .result-content .result-listbox .awards-box p {
      width: 164px;
      margin-bottom: 30px;
    }

    .result-content .result-listbox .awards-box .awards-logo {
      content: url(${AwardsLogoDesk});
      margin-bottom: auto;
    }

    /* patent-box */
    .result-content .result-listbox .patent-box .patent-icon {
      content: url(${PatentIconDesk});
      margin-bottom: 15px;
    }

    .result-content .result-listbox .patent-box span {      
      width: 240px;
      margin-bottom: 22px;
    }

    .result-content .result-listbox .patent-box p {
      width: 235px;
      margin-bottom: 57px;
    }

    .result-content .result-listbox .patent-box .patent-table {
      display: none;
    }

    .result-content .result-listbox .patent-box .patent-table-effect {
      display: none;
    }

    /* transaction-box */
    .result-content .result-listbox .transaction-box .transaction-icon {
      content: url(${TransactionIconDesk});
      margin-bottom: 10px;
    }

    .result-content .result-listbox .transaction-box span {
      margin-bottom: 22px;
    }

    .result-content .result-listbox .transaction-box .plusdesc {
      display: none;
    }

    .result-content .result-listbox .transaction-box .plusdesc-desk {
      display: block;
      width: 233px;
      font-size: 22px;
      margin-bottom: 20px;
    }

    .result-content .result-listbox .transaction-box .last-desc {
      font-size: 20px;
      width: 225px;
    }

    .result-content .result-listbox .transaction-box strong {
      font-size: 36px;
      font-weight: 500;
      margin-top: 0;
      margin-bottom: auto;
    }

    .result-content .result-listbox .transaction-box strong::after {
      content: "";
      width: 29px;
      height: 48px;
      display: inline-block;
      background-image: url(${RedUpIconDesk});
      margin-left: 15px;
    }

    /* certification-box */
    .result-content .result-listbox .certification-box {
      padding: 96px 156px 95px 156px;
    }

    .result-content .result-listbox .certification-box .certification-icon {
      content: url(${CertificationIconDesk});
      margin-bottom: 15px;
    }

    .result-content .result-listbox .certification-box ul {
      display: flex;
      gap: 91px;
      margin-top: 22px;
    }

    .result-content .result-listbox .certification-box li {
      position: relative;
      border: none;
      padding: 0;
      white-space:nowrap;
      min-width:120px;      
    }

    .result-content .result-listbox .certification-box li:nth-child(1)::after {
      content: "";
      position: absolute;
      top: 60%;
      right: -45.5px;
      transform: translate(0, -50%);
      width: 3px;
      height: 48px;
      background-color: #e1e1e1;
    }

    .result-content .result-listbox .certification-box li:nth-child(2)::after {
      content: "";
      position: absolute;
      top: 60%;
      right: -45.5px;
      transform: translate(0, -50%);
      width: 3px;
      height: 48px;
      background-color: #e1e1e1;
    }

    .result-content .result-listbox .certification-box li:nth-child(1) p {
      margin-bottom: 26px;
    }

    .result-content .result-listbox .certification-box li:nth-child(2) p {
      white-space: nowrap;
      margin-bottom: 38px;
    }

    .result-content .result-listbox .certification-box li:nth-child(3) p {
      margin-bottom: 24px;
    }

    .result-content .result-listbox .certification-box span {
      margin-bottom: 22px;
    }

    .result-content .result-listbox .certification-box p {
      font-size: 24px;
      font-weight: 400;
    }

    .result-content .result-listbox .certification-box .certification-logo1 {
      content: url(${Certificationlogo1Desk});
    }

    .result-content .result-listbox .certification-box .certification-logo2 {
      content: url(${Certificationlogo2Desk});
    }

    .result-content .result-listbox .certification-box .certification-logo3 {
      content: url(${Certificationlogo3Desk});
    }

    /* satisfaction-box */
    .result-content
      .result-listbox
      .satisfaction-box
      .setisfaction-percent-icon {
      content: url(${SetisfactionPercentIconDesk});
      margin-bottom: 15px;
    }

    .result-content .result-listbox .satisfaction-box .satisfaction-title {
      width: 162px;
      margin-bottom: 22px;
    }

    .result-content .result-listbox .satisfaction-box .satisfaction-desc {
      width: 218px;
      margin-bottom: 43px;
    }

    .result-content .result-listbox .satisfaction-box .plusdesc {
      order: 4;
      width: 182px;
      height: 106px;
      font-size: 16px;
      font-weight: 400;
      padding-top: 20px;
      padding-bottom: 15px;
      margin-bottom: auto;
    }
  }
`;

// ImplementPonit
export const ImplementPonit = styled.section`
  .implementpoint-content {
    width: 100%;
    height: auto;
    padding: 40px 0;
  }

  .implementpoint-content .implementpoint-intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
  }

  .implementpoint-content .implementpoint-intro .title {
    display: block;
    margin-bottom: 4px;
  }

  .implementpoint-content .implementpoint-intro .desc {
    width: 260px;
  }
  .implementpoint-content ul {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .implementpoint-content ul li {
    display: flex;
    gap: 16px;
    padding: 10px;
    background-color: #fafafa;
    border-radius: 10px;
  }

  .implementpoint-content ul li div {
    display: flex;
    flex-direction: column;
  }

  .implementpoint-content ul li span {
    color: ${Color.middleBlack};
    font-size: 12px;
    font-weight: 700;
    text-align: left;
    margin-bottom: 2px;
  }

  .implementpoint-content ul li p {
    color: ${Color.Black};
    font-size: 12px;
    font-weight: 400;
    text-align: left;
  }

  .implementpoint-content .implement-list-icon1 {
    content: url(${ImplementIcon1});
  }
  .implementpoint-content .implement-list-icon2 {
    content: url(${ImplementIcon2});
  }
  .implementpoint-content .implement-list-icon3 {
    content: url(${ImplementIcon3});
  }

  @media screen and (min-width: 768px) {
    .implementpoint-content {
      width: 100%;
      height: auto;
      padding: 200px 0;
    }

    .implementpoint-content .implementpoint-intro {
      margin-bottom: 88px;
    }

    .implementpoint-content .implementpoint-intro .title {
      display: block;
      margin-bottom: 12px;
    }

    .implementpoint-content .implementpoint-intro .desc {
      width: 100%;
    }

    .implementpoint-content ul {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 30px;
    }

    .implementpoint-content ul li {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0;
      padding: 40px 0 40px 0;
      background-color: #fafafa;
      border-radius: 10px;
      width: 100%;
      height: 265px;
    }

    .implementpoint-content ul li div {
      display: flex;
      flex-direction: column;
    }

    .implementpoint-content ul li img {
      width: 80px;
      height: 80px;
      margin-bottom: 12px;
    }

    .implementpoint-content ul li span {
      font-size: 32px;
      font-weight: 700;
      text-align: center;
      margin-bottom: 14px;
    }

    .implementpoint-content ul li p {
      font-size: 24px;
      font-weight: 400;
      text-align: center;
      white-space: nowrap;
    }

    .implementpoint-content .implement-list-icon1 {
      content: url(${ImplementIcon1Desk});
    }
    .implementpoint-content .implement-list-icon2 {
      content: url(${ImplementIcon2Desk});
    }
    .implementpoint-content .implement-list-icon3 {
      content: url(${ImplementIcon3Desk});
    }
  }
`;

// MiddleComent
export const MiddleComent = styled.section`
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(217, 217, 217, 0.45) 100%
  );

  .coment-content {
    display: flex;
    gap: 7px;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }

  .coment-content .coment-intro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    
  }

  .coment-content .coment-intro span {
    display: block;
    width: 174px;
    height: 34px;
    border-radius: 10px 0px 10px 10px;
    background-color: ${Color.Gray};
    color: ${Color.middleBlack};
    font-size: 12px;
    font-weight: 700;
    padding: 10px;
    white-space: nowrap;
  }

  .coment-content .coment-intro p {
    display: block;
    font-size: 10px;
    font-weight: 500;
    color: ${Color.middleBlack};
    width: 156px;
  }

  .coment-content .sign-icon {
    content: url(${MiddleComentIcon});
  }

  @media screen and (min-width: 768px) {
  .coment-content {
    display: flex;
    gap: 53px;
    justify-content: center;
    align-items: center;
    padding: 100px;
  }

  .coment-content .coment-intro span {
    display: block;
    width: 604px;
    height: 143px;
    border-radius: 40px 0px 40px 40px;
    background-color: ${Color.Gray};
    color: ${Color.middleBlack};
    font-size: 36px;
    font-weight: 700;
    padding: 46.5px 117.5px;
    margin-bottom: 22px;
  }

  .coment-content .coment-intro p {
    display: block;
    font-size: 26px;
    font-weight: 500;
    width: 660px;
  }

  .coment-content .sign-icon {
    content: url(${MiddleComentIconDesk});
  }
`;

// CustomerInterview
export const CustomerInterview = styled.section`
  background-color: #fefbf6;

  .interview-content {
    width: 100%;
    height: auto;
    padding: 40px 0;
  }

  .interview-content .interview-intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
  }

  .interview-content .interview-intro .title {
    margin-bottom: 4px;
  }

  .interview-content .interview-intro .desc {
    display: block;
    width: 235px;
    height: 40px;
  }

  .interview-content .interview-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
    padding: 21.5px 45px;
  }

  .interview-content .interview-card p {
    color: #363636;
    font-size: 10px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 14px;
  }

  .interview-content .interview-card span {
    color: #363636;
    font-size: 12px;
    font-weight: 500;
  }

  @media screen and (min-width: 768px) {
    .interview-content {
      width: 100%;
      height: auto;
      padding: 200px 0 140px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .interview-content .interview-intro {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 88px;
    }

    .interview-content .interview-intro .title {
      margin-bottom: 12px;
    }

    .interview-content .interview-intro .desc {
      display: block;
      width: 100%;
      height: 40px;
    }

    .interview-content .interview-card {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 934px;
      height: 437px;
      border-radius: 20px;
      background: #fff;
      box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.15);
      padding: 50px 119.5px;
    }

    .interview-content .interview-card p {
      font-size: 28px;
      font-weight: 400;
      text-align: center;
      line-height: 1.9;
      margin-bottom: 24px;
    }

    .interview-content .interview-card p::before {
      content: " ";
      display: block;
      width: 82px;
      height: 70px;
      position: absolute;
      top: 50px;
      left: 126.5px;
      background-image: url(${QuotesLeftIconDesk});
    }

    .interview-content .interview-card p::after {
      content: " ";
      display: block;
      width: 82px;
      height: 70px;
      position: absolute;
      top: 50px;
      right: 126.5px;
      background-image: url(${QuotesRightIconDesk});
    }

    .interview-content .interview-card span {
      font-size: 34px;
      font-weight: 500;
    }
  }
`;

// KioskLocation
export const KioskLocation = styled.section`
  position: relative;
  background: ${Color.warmWhite};

  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    content: " ";
    display: block;
    width: 100%;
    height: 83px;
    background: linear-gradient(
      180deg,
      rgba(250, 250, 250, 0) 0%,
      #f0ebe0 100%
    );
  }

  .kiosklocation-content {
    width: 100%;
    height: auto;
    padding: 40px 0 0 0;
  }

  .kiosklocation-content .kiosklocation-intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
  }

  .kiosklocation-content .kiosklocation-intro .title {
    display: block;
    margin-bottom: 4px;
  }

  .kiosklocation-content .kiosklocation-intro .desc {
    width: 229px;
    height: 40px;
  }

  .kiosklocation-content .kiosklocation-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 18px;
  }

  .kiosklocation-content .kiosklocation-card .location-icon {
    content: url(${LocationIcon});
  }

  .kiosklocation-content .kiosklocation-card a {
    display: block;
    border-radius: 5px;
  }

  .kiosklocation-content .kiosklocation-card button {
    font-size: 14px;
    font-weight: 500;
    color: ${Color.White};
    background-color: ${Color.Yellow};
    border-radius: 5px;
    padding: 8px 10px 8px 20px;
    cursor: pointer;
  }

  .kiosklocation-content .kiosklocation-card .arrow-right-icon {
    content: url(${ArrowRightIcon});
  }

  .kiosklocation-content .kiosklocation-card .kiosk-img {
    content: url(${KioskImg});
  }

  @media screen and (min-width: 768px) {
    &::after {
      height: 208px;
    }

    .kiosklocation-content {
      width: 100%;
      height: auto;
      padding: 200px 0 0 0;
    }

    .kiosklocation-content .kiosklocation-intro {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 30px;
    }

    .kiosklocation-content .kiosklocation-intro .title {
      display: block;
      margin-bottom: 12px;
    }

    .kiosklocation-content .kiosklocation-intro .desc {
      width: 100%;
      height: 40px;
    }

    .kiosklocation-content .kiosklocation-card {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0;
    }

    .kiosklocation-content .kiosklocation-card .location-icon {
      content: url(${LocationIconDesk});
      margin-bottom: 38px;
    }

    .kiosklocation-content .kiosklocation-card a {
      display: block;
      border-radius: 5px;
      padding: 27.5px 43px 27.5px 63px;
      background-color: ${Color.Yellow};
      margin-bottom: 58px;
    }

    .kiosklocation-content .kiosklocation-card button {
      font-size: 32px;
      font-weight: 700;
      color: ${Color.White};

      border-radius: 5px;
    }

    .kiosklocation-content .kiosklocation-card .arrow-right-icon {
      content: url(${ArrowRightIconDesk});
    }

    .kiosklocation-content .kiosklocation-card .kiosk-img {
      content: url(${KioskImgDesk});
    }
  }
`;

// News
export const News = styled.section`
  .news-content {
    width: 100%;
    height: auto;
    padding: 40px 0;
  }

  .news-content .title {
    text-align: center;
    display: block;
    margin-bottom: 15px;
  }

  .news-content .news-nav {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  .news-content .news-nav li {
    width: 100%;
    padding: 16px 0 10px 0;
    cursor: pointer;
  }

  .news-content .news-nav li p {
    display: block;
    font-size: 14px;
    font-weight: 500;
    color: #3c3c4399;
  }

  .news-content .news-nav .bgcolor {
    border-bottom: 2px solid #3f3f3f;
  }

  .news-content .news-nav .bgcolor p {
    color: #3f3f3f;
  }

  /* news-card */
  .news-content .news-card-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 20px;
  }

  .news-content .news-card-list p {
    color: ${Color.middleBlack};
  }
  .news-content .news-card-list span {
    color: ${Color.middleBlack};
  }

  /* article-list */
  .news-content .news-card-list .article-list a {
    display: block;
    background-color: ${Color.lightGray};
    border-radius: 10px 0px 0px 10px;
  }

  .news-content .news-card-list .article-list .article-card {
    display: flex;
    gap: 17.5px;
    width: 100%;
    height: 96px;
  }

  .news-content .news-card-list .article-list .article-card img {
    width: 96px;
    height: 96px;
    border-radius: 10px 0px 0px 10px;
    border-top: 1px solid ${Color.Gray};
    border-bottom: 1px solid ${Color.Gray};
    border-left: 1px solid ${Color.Gray};
  }

  .news-content .news-card-list .article-list .article-card .article-card-info {
    display: flex;
    flex-direction: column;
    padding: 14px 17.5px 14px 0;
    width: calc(100% - 114px);
  }

  .news-content
    .news-card-list
    .article-list
    .article-card
    .article-card-info
    .article-date {
    font-size: 12px;
    font-weight: 400;
    text-align: left;
  }

  .news-content
    .news-card-list
    .article-card
    .article-card-info
    .article-title {
    font-size: 12px;
    font-weight: 700;
    text-align: left;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .news-content .news-card-list .article-card .article-card-info .article-desc {
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  /* news-list */
  .news-content .news-card-list .news-list a {
    display: block;
    background-color: ${Color.lightGray};
    border-radius: 10px 0px 0px 10px;
  }

  .news-content .news-card-list .news-list .news-card {
    display: flex;
    gap: 17.5px;
    width: 100%;
    height: 97px;
  }

  .news-content .news-card-list .news-list .news-card img {
    width: 96px;
    height: 96px;
    border-radius: 10px 0px 0px 10px;
    border-top: 1px solid ${Color.Gray};
    border-bottom: 1px solid ${Color.Gray};
    border-left: 1px solid ${Color.Gray};
  }

  .news-content .news-card-list .news-list .news-card .news-card-info {
    display: flex;
    flex-direction: column;
    padding: 32px 12px 30px 0;
    width: calc(100% - 114px);
  }

  .news-content
    .news-card-list
    .news-list
    .news-card
    .news-card-info
    .news-date {
    font-size: 12px;
    font-weight: 400;
    text-align: left;
  }

  .news-content .news-card-list .news-card .news-card-info .news-title {
    font-size: 12px;
    font-weight: 700;
    text-align: left;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  /* infomation-list */
  .news-content .news-card-list .infomation-list {
    width: 100%;
    height: 105px;
    border-bottom: 1px solid ${Color.Gray};
  }

  .news-content .news-card-list .infomation-list:first-child {
    border-top: 1px solid ${Color.Black};
  }

  .news-content .news-card-list .infomation-list a {
    display: block;
    height: 100%;
    padding: 10px 0;
  }

  .news-content .news-card-list .infomation-list .news-card {
    display: flex;
    flex-direction: column;
  }

  .news-content
    .news-card-list
    .infomation-list
    .infomation-card
    .infomation-card-info {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .news-content
    .news-card-list
    .infomation-list
    .infomation-card
    .infomation-card-info
    .infomation-date {
    font-size: 12px;
    font-weight: 400;
    text-align: left;
  }

  .news-content
    .news-card-list
    .infomation-list
    .infomation-card
    .infomation-card-info
    .infomation-title {
    font-size: 12px;
    font-weight: 700;
    text-align: left;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .news-content
    .news-card-list
    .infomation-list
    .infomation-card
    .infomation-card-info
    .infomation-desc {
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  /* pagination */
  .news-content .pagenation-box .pagination {
    display: flex;
    justify-content: center;
    gap: 8px;
  }

  .news-content .pagenation-box .pagination li:first-child,
  .news-content .pagenation-box .pagination li:last-child {
    display: none;
  }

  .news-content .pagenation-box .pagination li a {
    display: block;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    border: 1px solid #dfe3e8;
    background: #fff;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    line-height: 32px;
    color: #212121;
  }

  .news-content .pagenation-box .pagination .active a {
    border: 1px solid ${Color.Yellow};
  }

  .news-content .pagenation-box .pagination .disabled a {
    background-color: ${Color.Gray};
  }

  @media screen and (min-width: 768px) {
    .news-content {
      width: 100%;
      height: auto;
      padding: 200px 0;
    }

    .news-content .title {
      text-align: center;
      display: block;
      margin-bottom: 38px;
    }

    .news-content .news-nav {
      display: flex;
      justify-content: space-between;
      margin-bottom: 50px;
    }

    .news-content .news-nav li {
      width: 100%;
      padding: 40.5px 0;
    }

    .news-content .news-nav li p {
      display: block;
      font-size: 36px;
      font-weight: 500;
      color: ${Color.darkGray};
    }

    .news-content .news-nav .bgcolor {
      border-bottom: 2px solid ${Color.middleBlack};
    }

    .news-content .news-nav .bgcolor p {
      color: ${Color.middleBlack};
    }

    /* news-card */
    .news-content .news-card-list {
      display: flex;
      flex-direction: row;
      gap: 30px;
      margin-bottom: 60px;
    }

    /* article-list */
    .news-content .news-card-list .article-list a {
      display: block;
      width: 380px;
      height: 564px;
      background-color: ${Color.lightGray};
      border-radius: 20px 20px 0px 0px;
    }

    .news-content .news-card-list .article-list .article-card {
      display: flex;
      flex-direction: column;
      gap: 30px;
      height: 100%;
      width: 380px;
    }

    .news-content .news-card-list .article-list .article-card figure {
      border-radius: 20px 20px 0px 0px;
      border-top: 1px solid ${Color.Gray};
      border-right: 1px solid ${Color.Gray};
      border-left: 1px solid ${Color.Gray};
      width: 380px;
      height: 380px;
      overflow: hidden;
    }

    .news-content .news-card-list .article-list .article-card figure img {
      width: 380px;
      height: 380px;
      border-radius: 20px 20px 0px 0px;
      transition: all 0.2s linear;
      border: none;
    }

    .news-content .news-card-list .article-list .article-card figure:hover img {
      transform: scale(1.2);
    }

    .news-content
      .news-card-list
      .article-list
      .article-card
      .article-card-info {
      display: flex;
      flex-direction: column;
      gap: 14px;
      padding: 0 23px 30px 23px;
      width: 380px;
      height: 184px;
    }

    .news-content
      .news-card-list
      .article-list
      .article-card
      .article-card-info
      .article-date {
      font-size: 20px;
      font-weight: 400;
      text-align: left;
      color: ${Color.Black};
    }

    .news-content
      .news-card-list
      .article-card
      .article-card-info
      .article-title {
      font-size: 24px;
      font-weight: 500;
      text-align: left;
      color: ${Color.Black};
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .news-content
      .news-card-list
      .article-card
      .article-card-info
      .article-desc {
      font-size: 24px;
      font-weight: 400;
      text-align: left;
      color: ${Color.Black};
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      /* text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical; */
    }

    /* news-list */
    .news-content .news-card-list.column {
      display: flex;
      flex-direction: column;
      gap: 0;
      margin-bottom: 60px;
    }

    .news-content .news-card-list .news-list {
      border-bottom: 1px solid ${Color.Gray};
    }

    .news-content .news-card-list .news-list:first-child {
      border-top: 1px solid ${Color.Black};
    }

    .news-content .news-card-list .news-list a {
      display: block;
      background-color: transparent;
    }

    .news-content .news-card-list .news-list .news-card {
      display: flex;
      gap: 40px;
      width: 100%;
      height: 254px;
    }

    .news-content .news-card-list .news-list .news-card figure {
      border-radius: 20px;
      border: 1px solid ${Color.Gray};
      width: 270px;
      height: 213px;
      overflow: hidden;
      margin-top: 20px;
    }

    .news-content .news-card-list .news-list .news-card figure img {
      width: 213px;
      height: 213px;
      border-radius: 20px;
      transition: all 0.2s linear;
    }

    .news-content .news-card-list .news-list .news-card figure:hover img {
      transform: scale(1.2);
    }

    .news-content .news-card-list .news-list .news-card img {
      border-radius: 20px;
      margin-top: 20px;
      /* border: 1px solid ${Color.Gray}; */
      border: none;
    }

    .news-content .news-card-list .news-list .news-card .news-card-info {
      display: flex;
      flex-direction: column;
      padding: 57.5px 0;
      width: 100%;
    }

    .news-content
      .news-card-list
      .news-list
      .news-card
      .news-card-info
      .news-date {
      font-size: 28px;
      font-weight: 400;
      text-align: left;
      margin-bottom: 20px;
    }

    .news-content .news-card-list .news-card .news-card-info .news-title {
      font-size: 28px;
      font-weight: 700;
      text-align: left;
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    /* infomation-list */
    .news-content .news-card-list .infomation-list {
      width: 100%;
      height: 237px;
      border-bottom: 1px solid ${Color.Gray};
    }

    .news-content .news-card-list .infomation-list:first-child {
      border-top: 1px solid ${Color.Black};
    }

    .news-content .news-card-list .infomation-list a {
      display: block;
      height: 100%;
      padding: 20px 0;
    }

    .news-content .news-card-list .infomation-list .news-card {
      display: flex;
      flex-direction: column;
    }

    .news-content
      .news-card-list
      .infomation-list
      .infomation-card
      .infomation-card-info {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    .news-content
      .news-card-list
      .infomation-list
      .infomation-card
      .infomation-card-info
      .infomation-date {
      font-size: 28px;
      font-weight: 400;
      text-align: left;
    }

    .news-content
      .news-card-list
      .infomation-list
      .infomation-card
      .infomation-card-info
      .infomation-title {
      font-size: 28px;
      font-weight: 700;
      text-align: left;
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .news-content
      .news-card-list
      .infomation-list
      .infomation-card
      .infomation-card-info
      .infomation-desc {
      font-size: 28px;
      font-weight: 400;
      text-align: left;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
`;

// AppAD
export const AppAD = styled.section`
  background-color: ${Color.lightYellow};

  .addad-content {
    width: 100%;
    height: auto;
    padding: 40px 0;
  }

  .addad-content .intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }

  .addad-content span {    
    display: block;
    font-size: 20px;
    font-weight: 700;
    color: ${Color.Black};
    margin-bottom: 10px;
    width: 262px;           
  }

  .addad-content p {
    align:center;
    display: block;
    font-size: 12px;
    font-weight: 500;    
    color: ${Color.Black};
  }

  .addad-content .desc1 {    
    width: 100%;
    margin-bottom: 20px;    
  }

  .addad-content .new_desc1 {
    width: 100%;
    margin-bottom: 20px;    
  }

  .addad-content .desc2 {
    width: 228px;
  }

  
  .addad-content .new_desc2 {
    width: 100%;
  }

  .addad-content .appdownload-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .addad-content .appdownload-box .body-iso-download {
    content: url(${ISOImg});
  }
  .addad-content .appdownload-box .body-android-download {
    content: url(${AndroidImg});
  }

  @media screen and (min-width: 768px) {
    .addad-content {
      width: 100%;
      height: auto;
      padding: 200px 0;
    }

    .addad-content .intro {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 64px;
    }

    .addad-content span {
      display: block;
      font-size: 44px;
      font-weight: 700;
      margin-bottom: 40px;
      width: 747px;
    }

    .addad-content p {
      display: block;
      font-size: 38px;
      font-weight: 500;
      white-space: nowrap;
    }

    .addad-content .desc1 {
      width: 568px;
      margin-bottom: 64px;
    }

    .addad-content .desc2 {
      width: 720px;
    }

    .addad-content .appdownload-box {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 42px;
    }

    .addad-content .appdownload-box .body-iso-download {
      content: url(${ISOImgDesk});
    }
    .addad-content .appdownload-box .body-android-download {
      content: url(${AndroidImgDesk});
    }
  }
`;

// SideNav.
export const SideNav = styled.div`
  display: none;

  @media screen and (min-width: 768px) {
    position: fixed;
    top: 30%;
    left: calc((100% - 1200px) / 2 + 1275px);
    transform: translate(0, -50%);
    display: block;
    transition: 3s;

    &.hidden {
      visibility: hidden;
    }

    .sidenav-listbox {
      display: flex;
      flex-direction: column;
      gap: 26px;
    }

    .sidenav-listbox li {
      display: flex;
      cursor: pointer;
    }

    .sidenav-listbox p {
      position: relative;
      font-size: 20px;
      font-weight: 500;
      color: ${Color.darkGray};
    }

    .sidenav-listbox .on p {
      position: relative;
      font-size: 20px;
      font-weight: 500;
      color: ${Color.Black};
    }

    .sidenav-listbox .on p::after {
      position: absolute;
      top: 50%;
      left: -25px;
      transform: translate(0, -50%);
      content: "";
      display: block;
      width: 22px;
      height: 22px;
      background-image: url(${SideNavIcon});
      background-repeat: no-repeat;
      background-size: 22px 22px;
      background-position: center center;
    }
  }
`;

// FixBTN
export const FixBTN = styled.div`
  position: fixed;
  right: 24px;
  bottom: 44px;
  z-index: 9999;

  div {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .float-top-button {
    width: 52px;
    height: 52px;
    border-radius: 50%;
    background-color: ${Color.White};
    background-image: url(${ArrowTopIcon});
    background-repeat: no-repeat;
    background-size: 18.571px 18.571px;
    background-position: center center;
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.15));
  }

  .introduction-button {
    width: 52px;
    height: 52px;
    border-radius: 50%;
    background-color: ${Color.Yellow};
    background-image: url(${ImplementIcon});
    background-repeat: no-repeat;
    background-size: 21.357px 20.429px;
    background-position: center center;
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.15));
  }

  @media screen and (min-width: 768px) {
    position: fixed;
    left: calc(((100vw - 1200px) / 2) + 1200px);
    /* right: 0; */
    bottom: 44px;

    div {
      display: flex;
      flex-direction: column;
      gap: 22px;
    }

    .float-top-button {
      width: 56px;
      height: 56px;
      background-image: url(${ArrowTopIconDesk});
      background-size: 20px 20px;
      cursor: pointer;
    }

    .introduction-button {
      width: 56px;
      height: 56px;
      background-image: url(${ImplementIconDesk});
      background-size: 20px 20px;
      transition: 0.3s ease-in-out;
      cursor: pointer;
    }

    .introduction-button::after {
      position: absolute;
      top: 50%;
      left: 21.5px;
      transform: translate(0, -50%);
      content: "";
      display: block;
      width: 144px;
      height: 34px;
      transition: 0.5s ease-in-out;
    }

    
    .introduction-button:hover {
      position: relative;
      width: 220px;
      height: 56px;
      border-radius: 30px;
      background-image: url(${ImplementIconDesk});
      background-size: 20px 20px;
      background-position: 176px 50%;
      cursor: pointer;
    }

    .introduction-button:hover::after {
      position: absolute;
      top: 50%;
      left: 21.5px;
      transform: translate(0, -50%);
      content: "빠른 도입 문의";
      display: block;
      width: 144px;
      height: 34px;
      font-size: 20px;
      font-weight: 500;
      line-height: 34px;
      color: ${Color.White};
    }

    .introduction-button_ko:hover::after {
      position: absolute;
      top: 50%;
      left: 21.5px;
      transform: translate(0, -50%);
      content: "빠른 도입 문의";
      display: block;
      text-align:right;
      width: 144px;
      height: 34px;
      font-size: 20px;
      font-weight: 500;
      line-height: 34px;    
      color: ${Color.White};
      
      text-align:right;
    }

    .introduction-button_en:hover::after {
      position: absolute;
      top: 50%;
      left: 21.5px;
      transform: translate(0, -50%);
      content: "Quick Implement";
      display: block;
      text-align:right;
      width: 144px;
      height: 34px;
      font-size: 18px;
      font-weight: 500;
      line-height: 34px;
      
      color: ${Color.White};
      
      
    }

    .introduction-button_jp:hover::after {
      position: absolute;
      top: 50%;
      left: 21.5px;
      transform: translate(0, -50%);
      content: "迅速なお問い合わせ";
      display: block;
      text-align:right;
      width: 144px;
      height: 34px;
      font-size: 16px;
      font-weight: 500;
      line-height: 34px;
      color: ${Color.White};
    }

    .introduction-button_cn:hover::after {
      position: absolute;
      top: 50%;
      left: 21.5px;
      transform: translate(0, -50%);
      content: "快速咨询";
      display: block;      
      text-align:right;      
      width: 144px;
      height: 34px;
      font-size: 20px;
      font-weight: 500;
      line-height: 34px;
      color: ${Color.White};
    }

    .introduction-button_ct:hover::after {
      position: absolute;
      top: 50%;
      left: 21.5px;
      transform: translate(0, -50%);
      content: "快速諮詢";
      display: block;
      text-align:right;
      width: 144px;
      height: 34px;
      font-size: 20px;
      font-weight: 500;
      line-height: 34px;
      color: ${Color.White};
    }

    

  }
`;
