import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  RouterProvider,
  createBrowserRouter,
  BrowserRouter,
} from "react-router-dom";
// bootstrap grid CSS
import "./grid.min.css";
// reset CSS
import "./index.css";
//react-i18next
import "./locales/i18n";
// pages
import Main from "./pages/Main/Main";
import NotFound from "./pages/NotFound/NotFound";
import KioskLocation from "./pages/KioskLocation/KioskLocation";
import TermsofService from "./pages/Terms/TermsofService";
import PrivacyPolicy from "./pages/Terms/PrivacyPolicy";
import OpenBankingTerms from "./pages/Terms/OpenBankingTerms";
import CustomerComplaint from "./pages/Terms/CustomerComplaint";
import Disclosureinformation from "./pages/Disclosureinformation/Disclosureinformation";
import { HelmetProvider } from "react-helmet-async";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <NotFound />,
    children: [
      { index: true, element: <Main /> },
      { path: "/kiosklocation", element: <KioskLocation /> },
      { path: "/terms/terms_of_service", element: <TermsofService /> },
      { path: "/terms/privacy_policy", element: <PrivacyPolicy /> },
      { path: "/terms/open_banking_terms", element: <OpenBankingTerms /> },
      { path: "/terms/customer_complaint", element: <CustomerComplaint /> },
      {
        path: "/notice/disclosure_information",
        element: <Disclosureinformation />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <RouterProvider router={router} />
    </HelmetProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
