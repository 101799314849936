import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import * as S from "./Terms.style";
//
import { useTranslation } from "react-i18next";
import i18n from "../../locales/i18n";

export default function OpenBankingTerms() {
  //
  const { t } = useTranslation();
  return (
    <>
      <Header />
      <S.Main>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="main-content">
                <p className="main-title">{t(`openbanking.오픈뱅킹약관`)}</p>
                <p className="main-desc">{t(`openbanking.설명`)}</p>
              </div>
            </div>
          </div>
        </div>
      </S.Main>
      <Footer />
    </>
  );
}
