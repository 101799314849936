import React, { useEffect, useState } from "react";
import * as S from "./Header.style";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "../../locales/i18n";
import GoogleTagManager from "../../gtm/GoogleTagManager";

export default function Header({ headeractive, changeLangNewsDesc }) {
  const [scrollPosition, setScrollPosition] = useState(false);
  const { t } = useTranslation();
  const [isLangBTN, setIsLangBTN] = useState(false);
  const [isNavBTN, setIsNavBTN] = useState(true);
  const [KO, setKO] = useState(true);
  const [EN, setEN] = useState(false);
  const [JP, setJP] = useState(false);
  const [CN, setCN] = useState(false);
  const [CT, setCT] = useState(false);

  //언어 변경하기
  const changeLanguage = (lang) => {
    changeLangNewsDesc(lang);
    i18n.changeLanguage(lang);
    setIsLangBTN(false);
  };

  //Header Scroll
  useEffect(() => {
    window.addEventListener("scroll", () => {
      // console.log(window.scrollY);
      if (window.scrollY > 0) {
        setScrollPosition(true);
      } else {
        setScrollPosition(false);
      }
    });
  }, [window.scrollY]);

  return (
    <S.Header
      $isNavBTN={isNavBTN}
      className={scrollPosition ? "header-active" : ""}
    >
      <GoogleTagManager gtmId="GTM-NC9TXH7R" />

      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="header-content">
              {/* gnb-logo */}
              <div className="gnb-logo">
                <a href="/">
                  <h1 className="blind">Money Plex</h1>
                </a>
              </div>
              <div className="gnb-right-menu">
                {/* gnb-nav-menu */}
                <div className="gnb-nav-menu">
                  <button
                    type="button"
                    aria-label="메뉴바"
                    className="nav-button"
                    onClick={() => {
                      setIsNavBTN(!isNavBTN);
                    }}
                  />
                  <nav className="nav-bar">
                    <ul className="nav-list">
                      <li className="nav-kiosk-location">
                        <Link to="/kiosklocation">
                          {t(`header.키오스크 위치`)}
                          {/* <a>키오스크 위치</a> */}
                        </Link>
                      </li>
                      <li className="nav-customer-center">
                        <a href="https://l7kye.channel.io/">
                          {t(`header.고객센터`)}
                        </a>
                      </li>
                      <li className="nav-asked-question">
                        <a href="https://moneyplex.notion.site/FAQ-f25376c92b23463c8ccb42d876e18419?pvs=4">
                          {t(`header.자주 묻는 질문`)}
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
                {/* gnb-lang-menu */}
                <div className="gnb-lang-menu">
                  <button
                    type="button"
                    aria-label="다국어"
                    className={`lang-button ${isLangBTN ? "on" : ""}`}
                    onClick={() => {
                      setIsLangBTN(!isLangBTN);
                    }}
                  >
                    <img src="" alt="다국어" />
                  </button>
                  {isLangBTN && (
                    <div className="gnb-popover">
                      <button
                        type="button"
                        className={`gnb-popover-btn ${KO ? "on" : ""}`}
                        onClick={() => {
                          changeLanguage("ko");
                          setKO(true);
                          setEN(false);
                          setJP(false);
                          setCN(false);
                          setCT(false);
                        }}
                      >
                        KR
                      </button>
                      {
                        <button
                          type="button"
                          className={`gnb-popover-btn ${EN ? "on" : ""}`}
                          onClick={() => {
                            changeLanguage("en");
                            setKO(false);
                            setEN(true);
                            setJP(false);
                            setCN(false);
                            setCT(false);
                          }}
                        >
                          EN
                        </button>
                      }
                      {
                        <button
                          type="button"
                          className={`gnb-popover-btn ${JP ? "on" : ""}`}
                          onClick={() => {
                            changeLanguage("jp");
                            setKO(false);
                            setEN(false);
                            setJP(true);
                            setCN(false);
                            setCT(false);
                          }}
                        >
                          JP
                        </button>
                      }
                      <button
                        type="button"
                        className={`gnb-popover-btn ${CN ? "on" : ""}`}
                        onClick={() => {
                          changeLanguage("cn");
                          setKO(false);
                          setEN(false);
                          setJP(false);
                          setCN(true);
                          setCT(false);
                        }}
                      >
                        CN
                      </button>
                      <button
                        type="button"
                        className={`gnb-popover-btn last-btn ${CT ? "on" : ""}`}
                        onClick={() => {
                          changeLanguage("ct");
                          setKO(false);
                          setEN(false);
                          setJP(false);
                          setCN(false);
                          setCT(true);
                        }}
                      >
                        CT
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </S.Header>
  );
}
