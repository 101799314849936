import { styled } from "styled-components";
// img
import HeaderLogo from "../../assets/image/header/header_logo.svg";
import HeaderLogoDesk from "../../assets/image/header/header_logo_desk.svg";
import NavbarIcon from "../../assets/image/header/navbar_icon.svg";
import NavbarCloseIcon from "../../assets/image/header/navbar_close_icon.svg";
import LangIcon from "../../assets/image/header/lang_icon.svg";
import LangIconWhite from "../../assets/image/header/lang_icon_white.svg";
import LangIconDesk from "../../assets/image/header/lang_icon_desk.svg";
import LangIconDeskWhite from "../../assets/image/header/lang_icon_desk_white.svg";
import Color from "../../assets/color/color";

/* header */
export const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 100;
  background-color: ${Color.White};
  border-bottom: 1px solid #dbdbdb;

  &.header-active {
    border-bottom: none;
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.1);
  }

  .header-content {
    height: 54px;
    display: flex;
    justify-content: space-between;
    padding: 15px 0;
  }

  /* header gnb-logo */

  .header-content .gnb-logo a {
    display: block;
    width: 84px;
    height: 24px;
    background-image: url(${HeaderLogo});
    background-repeat: no-repeat;
    background-size: contain;
  }

  /* header gnb-right-menu */

  .header-content .gnb-right-menu {
    display: flex;
    gap: 15px;
  }

  .header-content .gnb-right-menu > div:nth-child(1) {
    order: 2;
  }

  .header-content .gnb-right-menu > div:nth-child(2) {
    order: 1;
  }

  /* header gnb-right-menu > gnb-nav-menu */

  .header-content .gnb-nav-menu .nav-button {
    width: 24px;
    height: 24px;
    background-image: url(${(props) =>
      props.$isNavBTN ? NavbarIcon : NavbarCloseIcon});
    background-repeat: no-repeat;
    background-size: contain;
  }

  .header-content .gnb-nav-menu .nav-bar {
    display: ${(props) => (props.$isNavBTN ? "none" : "block")};
    position: absolute;
    top: 54px;
    left: -24px;
    right: -24px;
    height: 100vh;
    padding: 30px 24px;
    background-color: #fff;
  }

  .header-content .gnb-nav-menu .nav-bar .nav-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .header-content .gnb-nav-menu .nav-bar .nav-list a {
    display: inline-block;
    font-size: 18px;
    font-weight: 500;
  }

  /* header gnb-right-menu > gnb-lang-menu */
  /* .header-content .gnb-lang-menu {
    display: none;
  } */

  .header-content .gnb-lang-menu {
    position: relative;
  }

  .header-content .gnb-lang-menu .lang-button {
    position: absolute;
    top: 0;
    right: 0;
    width: 24px;
    height: 24px;
    /* background-image: url(${LangIcon}); */
    background-repeat: no-repeat;
    background-size: 18px 18px;
    background-position: center center;
    z-index: 9999;
  }

  .header-content .gnb-lang-menu .lang-button img {
    content: url(${LangIcon});
    width: 24px;
    height: 24px;
    z-index: 9999;
  }

  .header-content .gnb-lang-menu .lang-button.on img {
    content: url(${LangIconWhite});
    width: 24px;
    height: 24px;
    z-index: 9999;
  }

  .header-content .gnb-lang-menu .gnb-popover {
    position: absolute;
    top: -8px;
    right: -8px;
    padding: 44px 10px 10px 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: #fec131;
    border-radius: 50px;
    z-index: 1;
  }

  .header-content .gnb-lang-menu .gnb-popover button {
    width: 20px;
    height: 18px;
    border-bottom: 1px solid #c99c52;
    font-size: 12px;
    font-weight: 500;
    color: #c99c52;
  }

  .header-content .gnb-lang-menu .gnb-popover .gnb-popover-btn.on {
    color: #fff;
    border-bottom: 1px solid #fff;
  }

  .header-content .gnb-lang-menu .gnb-popover .last-btn {
    border-bottom: none;
  }

  /* >= 768px (Desktop) */
  @media screen and (min-width: 768px) {
    .header-content {
      height: 96px;
      display: flex;
      justify-content: space-between;
      padding: 29px 0;
    }

    /* gnb-logo */
    .header-content .gnb-logo a {
      display: block;
      width: 156px;
      height: 38px;
      background-image: url(${HeaderLogoDesk});
      background-repeat: no-repeat;
      background-size: contain;
    }

    /* header gnb-right-menu */
    .header-content .gnb-right-menu {
      display: flex;
      gap: 46px;
    }

    .header-content .gnb-right-menu > div:nth-child(1) {
      order: 1;
    }

    .header-content .gnb-right-menu > div:nth-child(2) {
      order: 2;
    }

    /* header gnb-right-menu > gnb-nav-menu */
    .header-content .gnb-nav-menu .nav-button {
      display: none;
    }

    .header-content .gnb-nav-menu .nav-bar {
      display: block;
      position: static;
      height: auto;
      padding: 0;
      background-color: #fff;
      margin-right: 30px;
    }

    .header-content .gnb-nav-menu .nav-bar .nav-list {
      display: flex;
      flex-direction: row;
      gap: 46px;
    }

    .header-content .gnb-nav-menu .nav-bar .nav-list a {
      display: block;
      font-size: 20px;
      font-weight: 500;
      line-height: 38px;
      height: 38px;
    }

    /* header gnb-right-menu > gnb-lang-menu */
    .header-content .gnb-lang-menu {
      position: relative;
    }

    .header-content .gnb-lang-menu .lang-button {
      position: absolute;
      top: 0;
      right: 0;
      width: 38px;
      height: 38px;
      /* background-image: url(${LangIconDesk});
      background-repeat: no-repeat;
      background-size: 28.5px 28.5px;
      background-position: center center; */
      z-index: 9999;
    }

    .header-content .gnb-lang-menu .lang-button img {
      content: url(${LangIconDesk});
      width: 38px;
      height: 38px;
      z-index: 9999;
    }

    .header-content .gnb-lang-menu .lang-button.on img {
      content: url(${LangIconDeskWhite});
      width: 38px;
      height: 38px;
      z-index: 9999;
    }

    .header-content .gnb-lang-menu .gnb-popover {
      position: absolute;
      top: -8px;
      right: -11px;
      padding: 48px 14px 10px 14px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      background-color: #fec131;
      border-radius: 50px;
      z-index: 1;
    }

    .header-content .gnb-lang-menu .gnb-popover button {
      width: 30px;
      height: 30px;
      border-bottom: 1px solid #c99c52;
      font-size: 20px;
      font-weight: 500;
      color: #c99c52;
    }

    .header-content .gnb-lang-menu .gnb-popover .last-btn {
      border-bottom: none;
    }
  }
`;
