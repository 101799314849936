import React from "react";
import * as S from "./Disclosureinformation.style";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

export default function Disclosureinformation() {
  return (
    <>
      <Header />
      <S.Main>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="main-content">
                <span className="notice-name">공시정보</span>
                <p className="main-date">2023.08.10</p>
                <p className="main-title">제3자 배정 유상증자 공고</p>
                <p className="main-desc">
                  제3자 배정 유상증자 공고 주식회사 코인트래빗은 상법 제416조에
                  의거 2023년 8월 10일 에 정관 제10조 제1항 2호에 의한 3자
                  배정방식의 신주발행을 결의하였기에 상법 제418조④항에 따라
                  주주들에게 아래와 같은 내용을 공고합니다.
                  <br />
                  <br />
                  제3자 배정 유상증자의 건 - 제 1 호<br />
                  1. 신주의 종류와 수 : 기명식 상환전환우선주 5,344 주 <br />
                  2. 신주의 발행방법 : 제3자배정 <br />
                  3. 신주의 발행가액 : 1주당 금 18,716원(액면가 : 금 500원)
                  <br />
                  4. 청약일 : 2023년 08월 25일 5. 주금납입일 : 2023년 08월 25일
                  <br />
                  5. 주금납입일 : 2023년 08월 25일
                  <br />
                  6. 총 납입금액 : 금 100,018,304원
                  <br />
                  7. 신주배정 인수자 : 한양대학교 블루라이언 벤처투자조합
                  <br />
                  <br />
                  2023년 08월 10일
                  <br />
                  <br />
                  주식회사 코인트래빗 경기도 성남시 수정구 대왕판교로 815,
                  기업지원허브 혁신기술존, 정보보호클러스터 463호 (시흥동)
                  사내이사 이종선
                </p>
              </div>
            </div>
          </div>
        </div>
      </S.Main>
      <Footer />
    </>
  );
}
