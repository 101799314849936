import React from "react";
import * as S from "./Footer.style";
import FooterLogo from "../../assets/image/footer/footer_logo.svg";
import { Link } from "react-router-dom";
//
import { useTranslation } from "react-i18next";
import i18n from "../../locales/i18n";

export default function Footer() {
  //
  const { t } = useTranslation();
  //언어 변경하기
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };
  return (
    <S.Footer>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="footer-content">
              <div className="top-content">
                <div className="left-content">
                  <img
                    src={FooterLogo}
                    alt="MoneyPlex"
                    className="footer-logo"
                  />
                  <ul className="list1">
                    <li>
                      <p>{t(`footer.(주) 코인트래빗`)}</p>
                      <p>{t(`footer.대표`)}</p>
                    </li>
                    <li>
                      <a href="tel:+82-1833-4283">
                        <p>{t(`footer.고객센터`)}</p>
                      </a>
                      <p>{t(`footer.평일`)}</p>
                    </li>
                  </ul>
                  <ul>
                    <li className="footer-inquirymail">
                      <a href="mailto:support@moneyplex.net">
                        <p>{t(`footer.이용문의`)}</p>
                      </a>
                    </li>
                    <li>
                      <p>{t(`footer.사업자 등록번호`)}</p>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <p>
                        {t(
                          `footer.일반, 온라인환전업 및 무인환전업 정식 등록 제131A00690호`
                        )}
                      </p>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <p>{t(`footer.벤처기업 인증 제 20200107270호`)}</p>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <p>{t(`footer.주소`)}</p>
                    </li>
                  </ul>
                  <ul className="accent-box">
                    <li className="footer-policy">
                      <Link to="/terms/terms_of_service">
                        <p>{t(`footer.서비스 이용약관`)}</p>
                      </Link>
                    </li>
                    <li className="footer-privacy">
                      <Link to="/terms/privacy_policy">
                        <p>{t(`footer.개인정보 처리방침`)}</p>
                      </Link>
                    </li>
                    <li className="footer-openbanking">
                      <Link to="/terms/open_banking_terms">
                        <p>{t(`footer.오픈뱅킹 약관`)}</p>
                      </Link>
                    </li>
                    <li className="footer-indemnification">
                      <Link to="/terms/customer_complaint">
                        <p>{t(`footer.고객 민원 및 손해배상 처리 절차`)}</p>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="right-content">
                  <ul className="link-box">
                    <li className="footer-link-blog">
                      <a href="https://blog.naver.com/cointravit">
                        <img alt="blog" className="link-blog" />
                      </a>
                    </li>
                    <li className="footer-link-insta">
                      <a href="https://www.instagram.com/moneyplex_cointravit/">
                        <img alt="insta" className="link-insta" />
                      </a>
                    </li>
                    <li className="footer-link-facebook">
                      <a href="https://www.facebook.com/moneyplexofficial/">
                        <img alt="facebook" className="link-facebook" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="bottom-content">
                <span>Copyright ©Cointravit Corp.</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </S.Footer>
  );
}
