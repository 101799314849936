import React, { useEffect, useState } from "react";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import * as S from "./KioskLocation.style";
import * as S2 from "../Main/Main.style";
import CAT from "../../assets/image/cat.jpg";
import axios from "axios";
// import axios from "../../api/axios";
import i18n from "../../locales/i18n";
import { Helmet } from "react-helmet-async";

export default function KioskLocation() {
  const [kioskData, setKioskData] = useState([]);

  const [Lang, setLang] = useState("");

  const fixbtn_class_introduction =
    "introduction-button introduction-button_" + i18n.language;

  // fix 버튼
  const [showButton, setShowButton] = useState(false);
  //TopButton
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  //TopButton
  const smoothScrollTo = (targetPosition, duration) => {
    const startPosition = window.scrollY || window.pageYOffset;
    const startTime =
      "now" in window.performance ? performance.now() : new Date().getTime();
    const distance = targetPosition - startPosition;

    const scrollTo = (time) => {
      const elapsed = time - startTime;
      window.scrollTo(0, easeInOut(elapsed, startPosition, distance, duration));

      if (elapsed < duration) {
        requestAnimationFrame(scrollTo);
      }
    };

    const easeInOut = (t, b, c, d) => {
      t /= d / 2;
      if (t < 1) return (c / 2) * t * t + b;
      t--;
      return (-c / 2) * (t * (t - 2) - 1) + b;
    };

    requestAnimationFrame(scrollTo);
  };

  const handleButtonClick = () => {
    const targetPosition = 0; // 원하는 스크롤 위치
    const duration = 2000; // 스크롤 속도를 조절하는 시간(ms)
    smoothScrollTo(targetPosition, duration);
  };

  const changeLangNewsDesc = (Lang) => {
    setLang(Lang);
  };
  useEffect(() => {
    console.log(Lang);
  }, [Lang]);

  //TopButton
  useEffect(() => {
    const handleShowButton = () => {
      if (window.scrollY > 500) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };
    window.addEventListener("scroll", handleShowButton);
    return () => {
      window.removeEventListener("scroll", handleShowButton);
    };
  }, []);

  let config = {
    method: "get",
    url: "/mpx_api/kioskinfo",
    headers: {
      "X-API-KEY": process.env.REACT_APP_MPX_API_KEY,
      "Content-Type": "application/json",
    },
  };

  useEffect(() => {
    axios
      .request(config)
      .then((response) => {
        console.log(response);
        setKioskData(() => {
          let kioskArray = [];
          response.data.areaList.forEach((v) => {
            kioskArray.push(...v.changerList);
          });
          return kioskArray;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const kioskName = (data) => {
    if (Lang === "" || Lang === "ko") {
      return data.kiosk_name;
    }
    if (Lang === "en") {
      return data.kiosk_name_en;
    }
    if (Lang === "jp") {
      return data.kiosk_name_jp;
    }
    if (Lang === "cn") {
      return data.kiosk_name_cn;
    }
    if (Lang === "th") {
      return data.kiosk_name_th;
    }
    if (Lang === "ct") {
      return data.kiosk_name_tw;
    }
  };
  const kioskAddress = (data) => {
    if (Lang === "" || Lang === "ko") {
      return data.kiosk_address;
    }

    if (Lang === "en") {
      return data.kiosk_address_en;
    }
    if (Lang === "jp") {
      return data.kiosk_address_jp;
    }
    if (Lang === "cn") {
      return data.kiosk_address_cn;
    }
    if (Lang === "th") {
      return data.kiosk_address_th;
    }
    if (Lang === "ct") {
      return data.kiosk_address_tw;
    }
  };

  const MONEYPLEX = {
    locationTite: {
      ko: "머니플렉스 설치점",
      "": "머니플렉스 설치점",
      en: "MoneyPlex Kiosk Location",
      jp: "マネーフレックスサービスセンター",
      cn: "MoneyPlex 服务中心",
      th: "ศูนย์บริการ MoneyFlex",
      ct: "MoneyPlex 服务中心",
    },
    kioskOpen: {
      ko: "영업시간",
      "": "영업시간",
      en: "Opening Hours",
      jp: "営業時間",
      cn: "营业时间",
      th: "เวลาทำการ",
      ct: "营业时间",
    },
  };
  return (
    <>
      <Helmet>
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="MoneyPlex" />
        <meta property="og:title" content="머니플렉스 키오스크 위치" />
        <meta
          property="og:description"
          content="머니플렉스 키오스크 위치 정보"
        />
      </Helmet>

      <Header changeLangNewsDesc={(lang) => changeLangNewsDesc(lang)} />

      <S.Main>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="main-content">
                <h2>{MONEYPLEX["locationTite"][Lang]}</h2>
                <div className="kiosk-list">
                  {kioskData.map((data) => (
                    <div className="kiosk-card">
                      <img
                        src={data.kiosk_img_url}
                        className="kiosk-img"
                        alt="키오스크 사진"
                      />
                      <div className="kiosk-info">
                        <span className="kiosk-name">{kioskName(data)}</span>
                        <p className="kiosk-adress">{kioskAddress(data)}</p>
                        <div className="kiosk-time">
                          <p>{MONEYPLEX["kioskOpen"][Lang]}</p>
                          <p>{data.kiosk_op_time}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        {showButton && (
          <S2.FixBTN>
            <div>
              <button
                type="button"
                aria-label="탑버튼"
                className="float-top-button"
                onClick={handleButtonClick}
              />
              <a
                href="https://forms.gle/6B5ZGmRWYfiNhgrJA"
                className="float-quickimplement-button"
              >
                <button
                  type="button"
                  aria-label="도입문의"
                  className={fixbtn_class_introduction}
                />
              </a>
            </div>
          </S2.FixBTN>
        )}
      </S.Main>

      <Footer />
    </>
  );
}
