import React, { useEffect } from "react";
import TagManager from "react-gtm-module";

export default function GoogleTagManager({ gtmId }) {

  useEffect(() => {
    TagManager.initialize({ gtmId });
  }, [gtmId]);
  return <></>;
}
