import { styled } from "styled-components";
import Color from "../../assets/color/color";

// Main
export const Main = styled.main`
  .main-content {
    width: 100%;
    padding-top: 74px;
    padding-bottom: 40px;
  }

  .main-content .main-title {
    font-size: 18px;
    font-weight: 700;
    color: ${Color.Black};
    margin-bottom: 15px;
  }

  .main-content .main-desc {
    font-size: 16px;
    font-weight: 400;
    color: ${Color.Black};
    text-align: left;
  }

  /* >= 768px (Desktop) */
  @media screen and (min-width: 768px) {
    margin-top: 96px;

    .main-content {
      width: 100%;
      padding-top: 100px;
      padding-bottom: 100px;
    }

    .main-content .main-title {
      font-size: 40px;
      font-weight: 500;
      color: ${Color.Black};
      margin-bottom: 40px;
    }

    .main-content .main-desc {
      font-size: 28px;
      font-weight: 500;
      color: ${Color.Black};
      text-align: left;
    }
  }
`;
