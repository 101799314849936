import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as S from "./Main.style";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
//
import Pagination from "react-js-pagination";
//
import AOS from "aos";
import "aos/dist/aos.css";
//
import { useTranslation } from "react-i18next";
import i18n from "../../locales/i18n";

import brand01 from "../../assets/image/section-partner/airport01.svg";
import brand02 from "../../assets/image/section-partner/airport02.svg";
import brand03 from "../../assets/image/section-partner/airport03.svg";
import brand04 from "../../assets/image/section-partner/airport04.svg";
import brand05 from "../../assets/image/section-partner/airport05.svg";
import brand06 from "../../assets/image/section-partner/airport06.svg";
import brand07 from "../../assets/image/section-partner/airport07.svg";
import brand08 from "../../assets/image/section-partner/airport08.svg";
import brand09 from "../../assets/image/section-partner/airport09.svg";
import brand10 from "../../assets/image/section-partner/airport10.svg";
import brand11 from "../../assets/image/section-partner/airport11.svg";
import brand12 from "../../assets/image/section-partner/airport12.svg";
import brand13 from "../../assets/image/section-partner/airport13.svg";
import brand14 from "../../assets/image/section-partner/airport14.svg";
import brand15 from "../../assets/image/section-partner/airport15.svg";
import brand16 from "../../assets/image/section-partner/airport16.svg";
import brand17 from "../../assets/image/section-partner/airport17.svg";
import brand18 from "../../assets/image/section-partner/airport18.svg";

// Sec-News
import { ReactComponent as ArrowLeftIcon } from "../../assets/image/section-news/arrow_left_icon.svg";
import { ReactComponent as ArrowRightIcon } from "../../assets/image/section-news/arrow_right_icon.svg";

const Loading01 = () =>{
  return
    <div></div>
}

export default function Main() {

  

  //
  const { t } = useTranslation();

  
  const [Lang, setLang] = useState("");
  

  // sidenav
  const [showSideNav, setShowSideNav] = useState(false);
  const [hiddenSideNav, setHiddenSideNav] = useState(false);
  const [showSec02, setShowSec02] = useState(false);
  const [showSec03, setShowSec03] = useState(false);
  const [showSec04, setShowSec04] = useState(false);
  const [showSec06, setShowSec06] = useState(false);
  const [showSec07, setShowSec07] = useState(false);
  const [showSec08, setShowSec08] = useState(false);
  // fix 버튼
  const [showButton, setShowButton] = useState(false);
  // 파트너 로고 더보기 버튼 (모바일 뷰)
  const [isPartnerBTN, setIsPartnerBTN] = useState(false);

  // Sec-News
  // 메뉴별 상태
  const [articleMenu, setArticleMenu] = useState(true);
  const [newsMenu, setNewsMenu] = useState(false);
  const [informationMenu, setInformationMenu] = useState(false);
  // 메뉴별 데이터
  const [articleData, setArticleData] = useState([]);
  const [newsData, setNewsData] = useState([]);
  const [informationData, setInformationData] = useState([]);
  // 페이지 네이션
  const [page, setPage] = useState(1);
  const [startnum, setStartNum] = useState(1);

  const limit = 3; // posts가 보일 최대한의 갯수
  const offset = (page - 1) * limit; // 시작점과 끝점을 구하는 offset

  const postsData = (posts) => {
    if (posts) {
      let result = posts.slice(offset, offset + limit);
      return result;
    }
  };

  const changeLangNewsDesc = (Lang) => {    
    setLang(Lang);
  };

    
  
  //SideNav
  //handleShowSideNav
  useEffect(() => {
    const handleShowSideNav = () => {
      if (window.scrollY > 500) {
        setShowSideNav(true);
      } else {
        setShowSideNav(false);
      }
    };
    window.addEventListener("scroll", handleShowSideNav);
    return () => {
      window.removeEventListener("scroll", handleShowSideNav);
    };
  }, []);

  useEffect(() => {
    const handleShowSideNav = () => {
      if (window.scrollY > 9095) {
        setHiddenSideNav(true);
      } else {
        setHiddenSideNav(false);
      }
    };
    window.addEventListener("scroll", handleShowSideNav);
    return () => {
      window.removeEventListener("scroll", handleShowSideNav);
    };
  }, []);

  //handleShowSection02
  useEffect(() => {
    const handleShowSection = () => {
      if (630 < window.scrollY < 1782) {
        setShowSec02(true);
        setShowSec03(false);
        setShowSec04(false);
        setShowSec06(false);
        setShowSec07(false);
        setShowSec08(false);
      }
    };
    window.addEventListener("scroll", handleShowSection);
    return () => {
      window.removeEventListener("scroll", handleShowSection);
    };
  }, []);

  //handleShowSection03
  useEffect(() => {
    const handleShowSection = () => {
      if (window.scrollY > 1782) {
        setShowSec02(false);
        setShowSec03(true);
        setShowSec04(false);
        setShowSec06(false);
        setShowSec07(false);
        setShowSec08(false);
      }
    };
    window.addEventListener("scroll", handleShowSection);
    return () => {
      window.removeEventListener("scroll", handleShowSection);
    };
  }, []);

  //handleShowSection04
  useEffect(() => {
    const handleShowSection = () => {
      if (window.scrollY > 3470) {
        setShowSec02(false);
        setShowSec03(false);
        setShowSec04(true);
        setShowSec06(false);
        setShowSec07(false);
        setShowSec08(false);
      }
    };
    window.addEventListener("scroll", handleShowSection);
    return () => {
      window.removeEventListener("scroll", handleShowSection);
    };
  }, []);

  //handleShowSection06
  useEffect(() => {
    const handleShowSection = () => {
      if (window.scrollY > 4900) {
        setShowSec02(false);
        setShowSec03(false);
        setShowSec04(false);
        setShowSec06(true);
        setShowSec07(false);
        setShowSec08(false);
      }
    };
    window.addEventListener("scroll", handleShowSection);
    return () => {
      window.removeEventListener("scroll", handleShowSection);
    };
  }, []);

  //handleShowSection07
  useEffect(() => {
    const handleShowSection = () => {
      if (window.scrollY > 5868) {
        setShowSec02(false);
        setShowSec03(false);
        setShowSec04(false);
        setShowSec06(false);
        setShowSec07(true);
        setShowSec08(false);
      }
    };
    window.addEventListener("scroll", handleShowSection);
    return () => {
      window.removeEventListener("scroll", handleShowSection);
    };
  }, []);

  //handleShowSection08
  useEffect(() => {
    const handleShowSection = () => {
      if (window.scrollY > 7103) {
        setShowSec02(false);
        setShowSec03(false);
        setShowSec04(false);
        setShowSec06(false);
        setShowSec07(false);
        setShowSec08(true);
      }
    };
    window.addEventListener("scroll", handleShowSection);
    return () => {
      window.removeEventListener("scroll", handleShowSection);
    };
  }, []);

  const scrollInToSec02 = document.querySelector(".section-partner");
  const scrollInToSec03 = document.querySelector(".section-result");
  const scrollInToSec04 = document.querySelector(".section-implementpoint");
  const scrollInToSec06 = document.querySelector(".section-interview");
  const scrollInToSec07 = document.querySelector(".section-kiosklocation");
  const scrollInToSec08 = document.querySelector(".section-news");

  //AOS
  useEffect(() => {
    AOS.init();
  });

  //TopButton
  // const scrollToTop = () => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: "smooth",
  //   });
  //   // console.log(window.scrollY);
  // };

//TopButton
    const smoothScrollTo = (targetPosition, duration) => {
      const startPosition = window.scrollY || window.pageYOffset;
      const startTime = 'now' in window.performance ? performance.now() : new Date().getTime();
      const distance = targetPosition - startPosition;
  
      const scrollTo = (time) => {
        const elapsed = time - startTime;
        window.scrollTo(0, easeInOut(elapsed, startPosition, distance, duration));
  
        if (elapsed < duration) {
          requestAnimationFrame(scrollTo);
        }
      };
  
      const easeInOut = (t, b, c, d) => {
        t /= d / 2;
        if (t < 1) return c / 2 * t * t + b;
        t--;
        return -c / 2 * (t * (t - 2) - 1) + b;
      };
  
      requestAnimationFrame(scrollTo);
    };
  
    const handleButtonClick = () => {
      const targetPosition = 0; // 원하는 스크롤 위치
      const duration = 2000; // 스크롤 속도를 조절하는 시간(ms)
      smoothScrollTo(targetPosition, duration);
    };

  

  //TopButton
  useEffect(() => {
    const handleShowButton = () => {
      if (window.scrollY > 500) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };
    window.addEventListener("scroll", handleShowButton);
    return () => {
      window.removeEventListener("scroll", handleShowButton);
    };
  }, []);

  // Sec-News 데이터 fetch
  useEffect(() => {
    if (articleMenu == true) {
      fetch(`data/list1.json`)
        .then((res) => res.json())
        .then((data) => {
          setArticleData(data);
        });
      return () => {
        // console.log("article 데이터 받아옴");
      };
    }
    if (newsMenu == true) {
      fetch(`data/list2.json`)
        .then((res) => res.json())
        .then((data) => {
          setNewsData(data);
        });
      return () => {
        // console.log("news 데이터 받아옴");
      };
    }
    if (informationMenu == true) {
      fetch(`data/list3.json`)
        .then((res) => res.json())
        .then((data) => {
          setInformationData(data);
        });
      return () => {
        // console.log("information 데이터 받아옴");
      };
    }
  }, [articleMenu, newsMenu, informationMenu]);

  const clickArticleMenu = () => {
    setArticleMenu(true);
    setNewsMenu(false);
    setInformationMenu(false);
  };
  const clickNewsMenu = () => {
    setArticleMenu(false);
    setNewsMenu(true);
    setInformationMenu(false);
  };
  const clickInformationMenu = () => {
    setArticleMenu(false);
    setNewsMenu(false);
    setInformationMenu(true);
  };

  const handlePageChange = (page) => {
    setPage(page);
    setStartNum((page - 1) * 3 + 1);
  };

  
  const class_introduction = "introduction-button introduction-button_"+i18n.language
  const fixbtn_class_introduction = "introduction-button introduction-button_"+i18n.language
  const class_intro1 = "intro1 intro1_"+i18n.language
  const class_intro2 = "intro2 intro2_"+i18n.language
  const class_intro1_h1 = "intro1_h1 intro1_h1_"+i18n.language

 
  return (
    <>
      
      {/* header */}
      <Header changeLangNewsDesc={(lang) => changeLangNewsDesc(lang)} />

      
      
      {/* main */}
      <main>
        <S.Main className="section-main">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="main-content">
                  <div className="main-inner">
                    <p className={class_intro1}>{t(`main.일상을 바꾸는 환전`)}</p>
                    <h1 className={class_intro1_h1}>{t(`main.머니플렉스`)}</h1>
                    <p className={class_intro2}>
                      {t(`main.지금 머니플렉스 하세요!`)}
                    </p>
                    <div className="download-box">
                      <a
                        href="https://moneyplex.page.link/main"
                        className="main-iso-download"
                      />
                      <a
                        href="https://moneyplex.page.link/main"
                        className="main-android-download"
                      />
                    </div>
                    <a
                      href="https://forms.gle/6B5ZGmRWYfiNhgrJA"
                      className="main-quickimplement-button quickimplement_click"
                    >
                      <button
                        type="button"
                        aria-label="빠른도입문의"
                        className={class_introduction}
                      >
                        <p>{t(`main.빠른 도입 문의`)}</p>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </S.Main>

        <S.Partner className="section-partner" isPartnerBTN={isPartnerBTN}>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="partner-content">
                  <div className="partner-intro">
                    <span className="title" data-aos="fade-up"   data-aos-anchor-placement="center-bottom">
                      {t(`partner.함께하는 파트너`)}
                    </span>
                    <p className="desc" data-aos="fade-up"   data-aos-anchor-placement="center-bottom">
                      {t(`partner.탑 티어 브랜드와 함께하는 이유가 있습니다.`)}
                    </p>
                  </div>
                  <div className="partner-list-box-mobile">
                    <div className="partner-list">
                      <figure>
                        <img src={brand01} alt="인천공항" />
                      </figure>
                      <figure>
                        <img src={brand02} alt="한국관광공사" />
                      </figure>
                      <figure>
                        <img src={brand03} alt="인천관광공사" />
                      </figure>
                      <figure>
                        <img src={brand04} alt="인천항만공사" />
                      </figure>
                      <figure>
                        <img src={brand05} alt="그랜드코리아레저" />
                      </figure>
                      <figure>
                        <img src={brand06} alt="중소벤처기업부" />
                      </figure>
                      <figure>
                        <img src={brand07} alt="경기창조경제혁신센터" />
                      </figure>
                      <figure>
                        <img src={brand08} alt="한국생산성본부" />
                      </figure>
                      <figure>
                        <img src={brand09} alt="K-STARTUP" />
                      </figure>
                      {/* 9개 이상 */}
                      <figure className="partner-plus">
                        <img src={brand10} alt="KB국민은행" />
                      </figure>
                      <figure className="partner-plus">
                        <img src={brand11} alt="KB인베스트먼트" />
                      </figure>
                      <figure className="partner-plus">
                        <img src={brand12} alt="NEWBORN VENTURES" />
                      </figure>
                      <figure className="partner-plus">
                        <img src={brand13} alt="한국사회투자" />
                      </figure>
                      <figure className="partner-plus">
                        <img src={brand14} alt="한국벤처투자" />
                      </figure>
                      <figure className="partner-plus">
                        <img src={brand15} alt="HYU Holdings" />
                      </figure>
                      <figure className="partner-plus">
                        <img src={brand16} alt="THE HYUNDAI" />
                      </figure>
                      <figure className="partner-plus">
                        <img src={brand17} alt="emart" />
                      </figure>
                      <figure className="partner-plus">
                        <img src={brand18} alt="SEVEN LUCK CASINO " />
                      </figure>
                    </div>
                  </div>
                  <button
                    type="button"
                    aria-label="파트너 더보기"
                    className="partner-plus-button"
                    onClick={() => {
                      setIsPartnerBTN(!isPartnerBTN);
                    }}
                  />
                  <div className="partner-list-box-pc">
                    <div className="partner-slide1">
                      <ul>
                        <li>
                          <figure>
                            <img src={brand01} alt="인천공항" />
                          </figure>
                        </li>
                        <li>
                          <figure>
                            <img src={brand02} alt="한국관광공사" />
                          </figure>
                        </li>
                        <li>
                          <figure>
                            <img src={brand03} alt="인천관광공사" />
                          </figure>
                        </li>
                        <li>
                          <figure>
                            <img src={brand04} alt="인천항만공사" />
                          </figure>
                        </li>
                        <li>
                          <figure>
                            <img src={brand05} alt="그랜드코리아레저" />
                          </figure>
                        </li>
                        <li>
                          <figure>
                            <img src={brand06} alt="중소벤처기업부" />
                          </figure>
                        </li>
                        <li>
                          <figure>
                            <img src={brand01} alt="인천공항" />
                          </figure>
                        </li>
                        <li>
                          <figure>
                            <img src={brand02} alt="한국관광공사" />
                          </figure>
                        </li>
                        <li>
                          <figure>
                            <img src={brand03} alt="인천관광공사" />
                          </figure>
                        </li>
                        <li>
                          <figure>
                            <img src={brand04} alt="인천항만공사" />
                          </figure>
                        </li>
                        <li>
                          <figure>
                            <img src={brand05} alt="그랜드코리아레저" />
                          </figure>
                        </li>
                        <li>
                          <figure>
                            <img src={brand06} alt="중소벤처기업부" />
                          </figure>
                        </li>
                        <li>
                          <figure>
                            <img src={brand01} alt="인천공항" />
                          </figure>
                        </li>
                        <li>
                          <figure>
                            <img src={brand02} alt="한국관광공사" />
                          </figure>
                        </li>
                        <li>
                          <figure>
                            <img src={brand03} alt="인천관광공사" />
                          </figure>
                        </li>
                        <li>
                          <figure>
                            <img src={brand04} alt="인천항만공사" />
                          </figure>
                        </li>
                        <li>
                          <figure>
                            <img src={brand05} alt="그랜드코리아레저" />
                          </figure>
                        </li>
                        <li>
                          <figure>
                            <img src={brand06} alt="중소벤처기업부" />
                          </figure>
                        </li>
                      </ul>
                    </div>
                    <div className="partner-slide2">
                      <ul>
                        <li>
                          <figure>
                            <img src={brand07} alt="경기창조경제혁신센터" />
                          </figure>
                        </li>
                        <li>
                          <figure>
                            <img src={brand08} alt="한국생산성본부" />
                          </figure>
                        </li>
                        <li>
                          <figure>
                            <img src={brand09} alt="K-STARTUP" />
                          </figure>
                        </li>
                        <li>
                          <figure>
                            <img src={brand10} alt="KB국민은행" />
                          </figure>
                        </li>
                        <li>
                          <figure>
                            <img src={brand11} alt="KB인베스트먼트" />
                          </figure>
                        </li>
                        <li>
                          <figure>
                            <img src={brand12} alt="NEWBORN VENTURES" />
                          </figure>
                        </li>
                        <li>
                          <figure>
                            <img src={brand13} alt="한국사회투자" />
                          </figure>
                        </li>
                        <li>
                          <figure>
                            <img src={brand07} alt="경기창조경제혁신센터" />
                          </figure>
                        </li>
                        <li>
                          <figure>
                            <img src={brand08} alt="한국생산성본부" />
                          </figure>
                        </li>
                        <li>
                          <figure>
                            <img src={brand09} alt="K-STARTUP" />
                          </figure>
                        </li>
                        <li>
                          <figure>
                            <img src={brand10} alt="KB국민은행" />
                          </figure>
                        </li>
                        <li>
                          <figure>
                            <img src={brand11} alt="KB인베스트먼트" />
                          </figure>
                        </li>
                        <li>
                          <figure>
                            <img src={brand12} alt="NEWBORN VENTURES" />
                          </figure>
                        </li>
                        <li>
                          <figure>
                            <img src={brand13} alt="한국사회투자" />
                          </figure>
                        </li>
                        <li>
                          <figure>
                            <img src={brand07} alt="경기창조경제혁신센터" />
                          </figure>
                        </li>
                        <li>
                          <figure>
                            <img src={brand08} alt="한국생산성본부" />
                          </figure>
                        </li>
                        <li>
                          <figure>
                            <img src={brand09} alt="K-STARTUP" />
                          </figure>
                        </li>
                        <li>
                          <figure>
                            <img src={brand10} alt="KB국민은행" />
                          </figure>
                        </li>
                        <li>
                          <figure>
                            <img src={brand11} alt="KB인베스트먼트" />
                          </figure>
                        </li>
                        <li>
                          <figure>
                            <img src={brand12} alt="NEWBORN VENTURES" />
                          </figure>
                        </li>
                        <li>
                          <figure>
                            <img src={brand13} alt="한국사회투자" />
                          </figure>
                        </li>
                      </ul>
                    </div>
                    <div className="partner-slide3">
                      <ul>
                        <li>
                          <figure>
                            <img src={brand14} alt="한국벤처투자" />
                          </figure>
                        </li>
                        <li>
                          <figure>
                            <img src={brand15} alt="HYU Holdings" />
                          </figure>
                        </li>
                        <li>
                          <figure>
                            <img src={brand16} alt="THE HYUNDAI" />
                          </figure>
                        </li>
                        <li>
                          <figure>
                            <img src={brand17} alt="emart" />
                          </figure>
                        </li>
                        <li>
                          <figure>
                            <img src={brand18} alt="SEVEN LUCK CASINO " />
                          </figure>
                        </li>
                        <li>
                          <figure>
                            <img src={brand14} alt="한국벤처투자" />
                          </figure>
                        </li>
                        <li>
                          <figure>
                            <img src={brand15} alt="HYU Holdings" />
                          </figure>
                        </li>
                        <li>
                          <figure>
                            <img src={brand16} alt="THE HYUNDAI" />
                          </figure>
                        </li>
                        <li>
                          <figure>
                            <img src={brand17} alt="emart" />
                          </figure>
                        </li>
                        <li>
                          <figure>
                            <img src={brand18} alt="SEVEN LUCK CASINO " />
                          </figure>
                        </li>
                        <li>
                          <figure>
                            <img src={brand14} alt="한국벤처투자" />
                          </figure>
                        </li>
                        <li>
                          <figure>
                            <img src={brand15} alt="HYU Holdings" />
                          </figure>
                        </li>
                        <li>
                          <figure>
                            <img src={brand16} alt="THE HYUNDAI" />
                          </figure>
                        </li>
                        <li>
                          <figure>
                            <img src={brand17} alt="emart" />
                          </figure>
                        </li>
                        <li>
                          <figure>
                            <img src={brand18} alt="SEVEN LUCK CASINO " />
                          </figure>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </S.Partner>

        <S.Result className="section-result">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="result-content">
                  <div className="result-intro">
                    <span className="title" data-aos="fade-up"   data-aos-anchor-placement="center-bottom">
                      {t(`result.고객 만족을 위한`)}
                      <br className="moblie_space" />
                      &nbsp;{t(`result.끊임 없는 고민과 도전`)}
                    </span>
                    <p className="desc" data-aos="fade-up"   data-aos-anchor-placement="center-bottom">
                      {t(`result.머니플렉스는 최고의`)}
                      <br className="moblie_space" />{" "}
                      {t(`result.환전 서비스를 만들어갑니다.`)}
                    </p>
                  </div>
                  <ul className="result-listbox" data-aos="fade-up"   data-aos-anchor-placement="center-bottom">
                    <li className="awards-box">
                      <img alt="수상 아이콘" className="awards-icon" />
                      <span>{t(`result.수상 실적`)}</span>
                      <p>
                        {t(`result.대한민국`)}
                        <br className="desk_space" />
                        {t(`result.4차 산업 혁명대상 기관장상`)}
                      </p>
                      <img alt="과학기술정보통신부" className="awards-logo" />
                    </li>
                    <li className="patent-box">
                      <img alt="특허 아이콘" className="patent-icon" />
                      <span>
                        {t(`result.국 ・ 내외 지재권 `)}
                        <br className="desk_space" />
                        {t(`result.출원 및 등록`)}
                      </span>
                      <p className="p-padding">
                        {t(`result.머니플렉스는 무인환전 `)}
                        <br className="desk_space" />
                        {t(`result.업계 최초, 최다 14개 자체`)}
                        <br className="desk_space" />
                        {t(`result.특허를 등록 및 출원`)}
                        <br className="desk_space" />
                        {t(`result.하였습니다.`)}
                      </p>
                      <img alt="특허 관련 표" className="patent-table" />
                      <img
                        alt="특허 관련 표 효과"
                        className="patent-table-effect"
                      />
                    </li>
                    <li className="transaction-box">
                      <img alt="거래 아이콘" className="transaction-icon" />
                      <span>{t(`result.누적 거래 건`)}</span>
                      <p className="plusdesc">
                        {t(
                          `result.관공서,공공기관,대기업,호텔 등 주요 고객사와 함께 머니플렉스는 성장하고 있습니다.`
                        )}
                      </p>
                      <p className="plusdesc-desk">
                        {t(`result.공공기관,대기업,호텔 등 주요 고객사와 함께`)}
                        <br className="desk_space" />
                        {t(`result.성장하고 있습니다.`)}
                      </p>
                      <p className="last-desc">
                        {t(`result.작년 대비 월 거래 건수 평균`)}
                      </p>
                      <strong>{t(`result.200% 증가`)}</strong>
                    </li>
                    <li className="certification-box">
                      <img alt="인증현황" className="certification-icon" />
                      <span>{t(`result.인증현황`)}</span>
                      <ul>
                        <li>
                          <p>{t(`result.KC인증`)}</p>
                          <img alt="KC인증" className="certification-logo1" />
                        </li>
                        <li>
                          <p>{t(`result.ICT품질인증`)}</p>
                          <img
                            alt="ICT품질인증"
                            className="certification-logo2"
                          />
                        </li>
                        <li>
                          <p>ISO9001</p>
                          <img alt="ISO9001" className="certification-logo3" />
                        </li>
                      </ul>
                    </li>
                    <li className="satisfaction-box">
                      <img
                        alt="만족도 현황 아이콘"
                        className="setisfaction-percent-icon"
                      />
                      <span className="satisfaction-title">
                        {t(`result.높은 사용자`)}
                        <br className="desk_space" />
                        {t(`result.만족도`)}
                      </span>
                      <p className="satisfaction-desc">
                        {t(`result.고객사와 환전 이용객의`)}
                        <br className="desk_space" />
                        {t(`result.압도적인 만족경험을`)}
                        <br className="desk_space" />
                        {t(`result.제공합니다.`)}
                      </p>
                      <p className="plusdesc">
                        {t(`result.키오스크 이용 고객 대상`)}
                        <br className="desk_space" />
                        {t(`result.서베이 진행 결과,`)}
                        <br className="desk_space" />
                        {t(`result.2023`)}
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </S.Result>

        <S.ImplementPonit className="section-implementpoint">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="implementpoint-content">
                  <div className="implementpoint-intro">
                    <span className="title" data-aos="fade-up"   data-aos-anchor-placement="center-bottom">
                      {t(`implementpoint.도입 포인트`)}
                    </span>
                    <p className="desc" data-aos="fade-up"   data-aos-anchor-placement="center-bottom">
                      {t(
                        `implementpoint.인건비 절감, 매출 상승, 백 오피스 자동화로 입증된 강력한`
                      )}
                      <br className="desk_space" />
                      {t(`implementpoint.머니플렉스 도입 효과`)}
                    </p>
                  </div>
                  <ul data-aos="fade-up"   data-aos-anchor-placement="center-bottom">
                    <li>
                      <img
                        alt="인건비 절감 아이콘"
                        className="implement-list-icon1"
                      />
                      <div>
                        <span>{t(`implementpoint.인건비 절감`)}</span>
                        <p>{t(`implementpoint.환전 리소스를 절약하세요`)}</p>
                      </div>
                    </li>
                    <li>
                      <img
                        alt="매출 상승 아이콘"
                        className="implement-list-icon2"
                      />
                      <div>
                        <span>{t(`implementpoint.매출 상승`)}</span>
                        <p>
                          {t(`implementpoint.30초면 충분! 대기 없이 빠른 환전`)}
                        </p>
                      </div>
                    </li>
                    <li>
                      <img
                        alt="백 오피스 아이콘"
                        className="implement-list-icon3"
                      />
                      <div>
                        <span>{t(`implementpoint.백 오피스 자동화`)}</span>
                        <p>
                          {t(`implementpoint.매출 관리와 신고 업무 이제 그만`)}
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </S.ImplementPonit>

        <S.MiddleComent className="section-coment">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="coment-content">
                  <div className="coment-intro">
                    <span data-aos="fade-up"   data-aos-anchor-placement="center-bottom">
                      {t(`middlecoment.무작정 설치하지 마세요!`)}
                    </span>
                    <p data-aos="fade-up"   data-aos-anchor-placement="center-bottom">
                      {t(`middlecoment.비싼 사용료, 빈번한 장애,`)}
                      <br className="moblie_space" />
                      {t(`middlecoment.잦은 고객 민원은 이제 그만!`)}
                      <br className="desk_space" />
                      <br className="moblie_space" />
                      {t(`middlecoment.투명한 정산 관리와 저렴한 수수료,`)}
                      <br className="moblie_space" />
                      {t(`middlecoment.신속 정확한 CS 응대로`)}
                      <br className="desk_space" />
                      {t(
                        `middlecoment.머니플렉스가 파트너 사의 고민을 해결해 드립니다.`
                      )}
                    </p>
                  </div>
                  <img
                    alt="사인 아이콘"
                    className="sign-icon"
                    data-aos="fade-up"   data-aos-anchor-placement="center-bottom"
                  />
                </div>
              </div>
            </div>
          </div>
        </S.MiddleComent>

        <S.CustomerInterview className="section-interview">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="interview-content">
                  <div className="interview-intro">
                    <span className="title" data-aos="fade-up"   data-aos-anchor-placement="center-bottom">
                      {t(`interview.고객 인터뷰`)}
                    </span>
                    <p className="desc" data-aos="fade-up"  data-aos-anchor-placement="center-bottom">
                      {t(
                        `interview.관공서와 대기업을 통해 검증된 신속하고 안정적인 운영 지원`
                      )}
                    </p>
                  </div>
                  <div className="interview-card" data-aos="fade-up"   data-aos-anchor-placement="center-bottom">
                    <p>
                      {t(`interview.유치에만 급급하고 사후 지원은 제대로`)}
                      <br />
                      {t(`interview.이뤄지지 않아 답답했었는데,`)}
                      <br />
                      {t(`interview.머니플렉스는 도입 이후에도 CS 관리와`)}
                      <br />
                      {t(`interview.운영 지원을 신속하고 정확하게 처리해주고`)}
                      <br />
                      {t(
                        `interview.신뢰할 수 있는 환전 파트너라고 생각합니다.`
                      )}
                    </p>
                    <span>{t(`interview.A 고객사 운영지원본부 L팀장`)}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </S.CustomerInterview>

        <S.KioskLocation className="section-kiosklocation">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="kiosklocation-content" >
                  <div className="kiosklocation-intro" data-aos="fade-up"   data-aos-anchor-placement="center-bottom">
                    <span className="title">
                      {t(`kiosklocation.키오스크 위치`)}
                    </span>
                    <p className="desc">
                      {t(`kiosklocation.머니플렉스의 키오스크 위치를`)}
                      <br className="moblie_space" />
                      {t(`kiosklocation.다양하게 만나보세요.`)}
                    </p>
                  </div>
                  <div className="kiosklocation-card"  data-aos="fade-up"   data-aos-anchor-placement="center-bottom">
                    <img
                      alt="키오스크 위치 아이콘"
                      className="location-icon"
                    />
                    <Link
                      to="/kiosklocation"                      
                      className="main-kiosklocation-button"
                    >
                      <button
                        type="button"
                        aria-label="키오스크 위치 바로가기"
                        className="kiosk-location-button"
                      >
                        {t(`kiosklocation.키오스크 위치 바로가기`)}
                        <img
                          alt="바로가기 아이콘"
                          className="arrow-right-icon"
                        />
                      </button>
                    </Link>
                    <img
                      alt="키오스크"
                      className="kiosk-img"
                     
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </S.KioskLocation>

        <S.News className="section-news">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="news-content"  data-aos="fade-up"   data-aos-anchor-placement="center-bottom">
                  <span className="title">
                    {t(`news.머니플렉스 소식`)}
                  </span>
                  <ul className="news-nav">
                    <li
                      onClick={clickArticleMenu}
                      className={
                        articleMenu
                          ? "bgcolor main-article-tab"
                          : "main-article-tab"
                      }
                    >
                      <p>{t(`news.새소식`)}</p>
                    </li>
                    <li
                      onClick={clickNewsMenu}
                      className={
                        newsMenu ? "bgcolor main-news-tab" : "main-news-tab"
                      }
                    >
                      <p>{t(`news.뉴스`)}</p>
                    </li>
                    <li
                      onClick={clickInformationMenu}
                      className={
                        informationMenu ? "bgcolor main-ir-tab" : "main-ir-tab"
                      }
                    >
                      <p>{t(`news.공시정보`)}</p>
                    </li>
                  </ul>
                  {articleMenu && (
                    <ArticleCardBox articleMenu={articleMenu} page={page} />
                  )}
                  {newsMenu && (
                    <NewsCardBox articleMenu={articleMenu} page={page} />
                  )}
                  {informationMenu && (
                    <IRCardBox articleMenu={articleMenu} page={page} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </S.News>

        <S.AppAD className="section-addad">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="addad-content">
                  <div className="intro">
                    <span data-aos="fade-up"  data-aos-anchor-placement="center-bottom">
                      {t(
                        `addad.수수료 없이 100% 우대 환율로 제공하는 모바일 앱 환전 서비스 머니플렉스`
                      )}
                    </span>
                    <p className="new_desc1" data-aos="fade-up"   data-aos-anchor-placement="center-bottom">
                      {t(`addad.은행 업무가 끝난 저녁에도 걱정 없이`)}
                      <br />
                      {t(`addad.사설 환전소보다 저렴하고 투명하게`)}
                    </p>
                    <p className="new_desc2" data-aos="fade-up"   data-aos-anchor-placement="center-bottom">
                      {t(`addad.지금, 머니플렉스 앱을 다운로드하고`)}
                      <br />
                      {t(`addad.16개국 통화를 저렴하고 편리하게 환전하세요!`)}
                    </p>
                  </div>
                  <div className="appdownload-box" data-aos="fade-up"   data-aos-anchor-placement="center-bottom"> 
                    <a
                      href="https://moneyplex.page.link/main"
                      className="body-iso-download"
                    />
                    <a
                      href="https://moneyplex.page.link/main"
                      className="body-android-download"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </S.AppAD>

        {showSideNav && (
          <S.SideNav
            className={hiddenSideNav ? "hidden" : ""}
            data-aos="fade-up"  data-aos-anchor-placement="center-bottom"
          >
            <ul className="sidenav-listbox">
              <li
                className={`sidenav-list-1 ${showSec02 ? "on" : ""}`}
                onClick={() => {
                  scrollInToSec02.scrollIntoView({
                    behavior: "smooth",
                  });
                }}
              >
                <p>{t(`sidenav.함께하는 파트너`)}</p>
              </li>
              <li
                className={`sidenav-list-2 ${showSec03 ? "on" : ""}`}
                onClick={() => {
                  scrollInToSec03.scrollIntoView({
                    behavior: "smooth",
                  });
                }}
              >
                <p>{t(`sidenav.성과`)}</p>
              </li>
              <li
                className={`sidenav-list-3 ${showSec04 ? "on" : ""}`}
                onClick={() => {
                  scrollInToSec04.scrollIntoView({
                    behavior: "smooth",
                  });
                }}
              >
                <p>{t(`sidenav.도입 효과`)}</p>
              </li>
              <li
                className={`sidenav-list-4 ${showSec06 ? "on" : ""}`}
                onClick={() => {
                  scrollInToSec06.scrollIntoView({
                    behavior: "smooth",
                  });
                }}
              >
                <p>{t(`sidenav.고객 인터뷰`)}</p>
              </li>
              <li
                className={`sidenav-list-5 ${showSec07 ? "on" : ""}`}
                onClick={() => {
                  scrollInToSec07.scrollIntoView({
                    behavior: "smooth",
                  });
                }}
              >
                <p>{t(`sidenav.키오스크 위치`)}</p>
              </li>
              <li
                className={`sidenav-list-6 ${showSec08 ? "on" : ""}`}
                onClick={() => {
                  scrollInToSec08.scrollIntoView({
                    behavior: "smooth",
                  });
                }}
              >
                <p>{t(`sidenav.머니플렉스 소식`)}</p>
              </li>
            </ul>
          </S.SideNav>
        )}

        {showButton && (
          <S.FixBTN data-aos="fade-up"   data-aos-anchor-placement="center-bottom">
            <div>
              <button
                type="button"
                aria-label="탑버튼"
                className="float-top-button topbutton_float_click"
                onClick={handleButtonClick}
              />
              <a
                href="https://forms.gle/6B5ZGmRWYfiNhgrJA"
                className="float-quickimplement-button"
              >
                <button
                  type="button"
                  aria-label="도입문의"                  
                  className={fixbtn_class_introduction}
                />
              </a>
            </div>
          </S.FixBTN>
        )}
      </main>

      {/* footer */}
      <Footer />
    </>
  );
}

function ArticleCardBox(props) {
  const [articleData, setArticleData] = useState([]);

  // Sec-News 데이터 fetch
  useEffect(() => {
    fetch(`data/ko/list1.json`)
      .then((res) => res.json())
      .then((data) => {
        setArticleData(data);
      });
    return () => {
      // console.log("article 데이터 받아옴");
    };
  }, []);

  // 페이지 네이션
  const [page, setPage] = useState(1);
  const [startnum, setStartNum] = useState(1);

  const limit = 3; // posts가 보일 최대한의 갯수
  const offset = (page - 1) * limit; // 시작점과 끝점을 구하는 offset

  const postsData = (posts) => {
    if (posts) {
      let result = posts.slice(offset, offset + limit);
      return result;
    }
  };

  const handlePageChange = (page) => {
    setPage(page);
    setStartNum((page - 1) * 3 + 1);
  };

  return (
    <>
      <ul className={`news-card-list ${props.articleMenu ? " " : "column"}`}>
        {postsData(articleData).map((card, index) => (
          <li
            className={`article-list main-article-c${card.num.toString()}`}
            key={index}
          >
            <a href={card.link}>
              <div className="article-card">
                <figure>
                  <img src={card.img} alt="기사 메인 사진" />
                </figure>
                <div className="article-card-info">
                  <p className="article-date">{card.date}</p>
                  <span className="article-title">{card.title}</span>
                  <p className="article-desc">{card.desc}</p>
                </div>
              </div>
            </a>
          </li>
        ))}
      </ul>
      <div className="pagenation-box">
        <Pagination
          activePage={page} // 현재 페이지
          itemsCountPerPage={3} // 한 페이지당 보여줄 아이템 갯수
          totalItemsCount={5} // 총 아이템 갯수
          pageRangeDisplayed={2} // paginator의 페이지 범위
          onChange={handlePageChange} // 페이지 변경을 핸들링하는 함수
          firstPageText={<ArrowLeftIcon />}
          prevPageText={<ArrowLeftIcon />}
          lastPageText={<ArrowRightIcon />}
          nextPageText={<ArrowRightIcon />}
          itemClassFirst="left_endstep"
          itemClassPrev="left_onestep main-article-left"
          itemClassNext="right_onestep main-article-right"
          itemClassLast="right_endstep"
        />
      </div>
    </>
  );
}

function NewsCardBox(props) {
  const [newsData, setNewsData] = useState([]);

  // Sec-News 데이터 fetch
  useEffect(() => {
    fetch(`data/ko/list2.json`)
      .then((res) => res.json())
      .then((data) => {
        setNewsData(data);
      });
    return () => {
      // console.log("article 데이터 받아옴");
    };
  }, []);

  // 페이지 네이션
  const [page, setPage] = useState(1);
  const [startnum, setStartNum] = useState(1);

  const limit = 3; // posts가 보일 최대한의 갯수
  const offset = (page - 1) * limit; // 시작점과 끝점을 구하는 offset

  const postsData = (posts) => {
    if (posts) {
      let result = posts.slice(offset, offset + limit);
      return result;
    }
  };

  const handlePageChange = (page) => {
    setPage(page);
    setStartNum((page - 1) * 3 + 1);
  };

  return (
    <>
      <ul className={`news-card-list ${props.articleMenu ? " " : "column"}`}>
        {postsData(newsData).map((card, index) => (
          <li
            className={`news-list main-news-c${card.num.toString()}`}
            key={index}
          >
            <a href={card.link}>
              <div className="news-card">
                <figure>
                  <img src={card.img} alt="기사 메인 사진" />
                </figure>
                <div className="news-card-info">
                  <p className="news-date">{card.date}</p>
                  <span className="news-title">{card.title}</span>
                </div>
              </div>
            </a>
          </li>
        ))}
      </ul>
      <div className="pagenation-box">
        <Pagination
          activePage={page} // 현재 페이지
          itemsCountPerPage={3} // 한 페이지당 보여줄 아이템 갯수
          totalItemsCount={5} // 총 아이템 갯수
          pageRangeDisplayed={2} // paginator의 페이지 범위
          onChange={handlePageChange} // 페이지 변경을 핸들링하는 함수
          firstPageText={<ArrowLeftIcon />}
          prevPageText={<ArrowLeftIcon />}
          lastPageText={<ArrowRightIcon />}
          nextPageText={<ArrowRightIcon />}
          itemClassFirst="left_endstep"
          itemClassPrev="left_onestep main-news-left"
          itemClassNext="right_onestep main-news-right"
          itemClassLast="right_endstep"
        />
      </div>
    </>
  );
}

function IRCardBox(props) {
  const [informationData, setInformationData] = useState([]);

  // Sec-News 데이터 fetch
  useEffect(() => {
    fetch(`data/ko/list3.json`)
      .then((res) => res.json())
      .then((data) => {
        setInformationData(data);
      });
    return () => {
      // console.log("article 데이터 받아옴");
    };
  }, []);

  // 페이지 네이션
  const [page, setPage] = useState(1);
  const [startnum, setStartNum] = useState(1);

  const limit = 3; // posts가 보일 최대한의 갯수
  const offset = (page - 1) * limit; // 시작점과 끝점을 구하는 offset

  const postsData = (posts) => {
    if (posts) {
      let result = posts.slice(offset, offset + limit);
      return result;
    }
  };

  const handlePageChange = (page) => {
    setPage(page);
    setStartNum((page - 1) * 3 + 1);
  };

  return (
    <>
      <ul className={`news-card-list ${props.articleMenu ? " " : "column"}`}>
        {postsData(informationData).map((card, index) => (
          <li
            className={`infomation-list main-ir-c${card.num.toString()}`}
            key={index}
          >
            <Link to="/notice/disclosure_information">
              <div className="infomation-card">
                <div className="infomation-card-info">
                  <p className="infomation-date">{card.date}</p>
                  <span className="infomation-title">{card.title}</span>
                  <p className="infomation-desc">{card.desc}</p>
                </div>
              </div>
            </Link>
          </li>
        ))}
      </ul>
      <div className="pagenation-box">
        <Pagination
          activePage={page} // 현재 페이지
          itemsCountPerPage={1} // 한 페이지당 보여줄 아이템 갯수
          totalItemsCount={1} // 총 아이템 갯수
          pageRangeDisplayed={1} // paginator의 페이지 범위
          onChange={handlePageChange} // 페이지 변경을 핸들링하는 함수
          firstPageText={<ArrowLeftIcon />}
          prevPageText={<ArrowLeftIcon />}
          lastPageText={<ArrowRightIcon />}
          nextPageText={<ArrowRightIcon />}
          itemClassFirst="left_endstep"
          itemClassPrev="left_onestep main-ir-left"
          itemClassNext="right_onestep main-ir-right"
          itemClassLast="right_endstep"
        />
      </div>
    </>
  );
}
