import { styled } from "styled-components";
import Color from "../../assets/color/color";

// img
import BlogIcon from "../../assets/image/footer/blog_icon.svg";
import InstaIcon from "../../assets/image/footer/insta_icon.svg";
import FaceBookIcon from "../../assets/image/footer/facebook_icon.svg";
import BlogIconDesk from "../../assets/image/footer/blog_icon_desk.svg";
import InstaIconDesk from "../../assets/image/footer/insta_icon_desk.svg";
import FaceBookIconDesk from "../../assets/image/footer/facebook_icon_desk.svg";

export const Footer = styled.footer`
  background-color: ${Color.lightGray};

  .footer-content {
    padding: 20px 0;    
    
  }

  .footer-content .left-content .footer-logo {
    display: none;
    
  }

  .footer-content .left-content ul {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 10px;    
  }

  .footer-content .left-content ul li p {
    display: block;
    font-size: 10px;
    font-weight: 500;
    text-align: left;
    color: ${Color.Black};
    
  }

  .footer-content .left-content .list1 li {
    display: flex;
    
  }

  .footer-content .left-content .list1 li:first-child {
    gap: 11px;
  }

  .footer-content .left-content .list1 li:first-child p:last-child {
    position: relative;
  }

  .footer-content .left-content .list1 li:first-child p:last-child::after {
    content: " ";
    position: absolute;
    top: 40%;
    left: -5.5px;
    transform: translate(0, -50%);
    width: 1px;
    height: 8px;
    background-color: ${Color.darkGray};
    
  }

  .footer-content .left-content .list1 li:last-child {
    gap: 2px;
    
  }

  .footer-content .left-content .accent-box {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 17px;
    margin-bottom: 20px;
  }

  .footer-content .left-content .accent-box li p {
    position: relative;
    font-weight: 700;
    color: ${Color.Black};
    white-space: nowrap;
    
  }

  .footer-content .left-content .accent-box li:last-child p {
    margin-top: -10px;
    
  }

  .footer-content
    .left-content
    .accent-box
    li:not(:first-child, :last-child)
    p::after {
    content: " ";
    position: absolute;
    top: 40%;
    left: -8.5px;
    transform: translate(0, -50%);
    width: 1px;
    height: 8px;
    background-color: ${Color.darkGray};
    
  }

  .footer-content .right-content .link-box {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;    
    min-width:200px;    
  }

  .footer-content .right-content .link-box .link-blog {
    content: url(${BlogIcon});
  }
  .footer-content .right-content .link-box .link-insta {
    content: url(${InstaIcon});
  }
  .footer-content .right-content .link-box .link-facebook {
    content: url(${FaceBookIcon});
  }

  .footer-content .bottom-content span {
    display: block;
    padding-top: 14px;
    font-size: 10px;
    font-weight: 400;
    color: ${Color.Black};
    border-top: 1px solid ${Color.Gray};
  }

  @media screen and (min-width: 430px) {
    .footer-content .left-content .accent-box li:last-child p::after {
      content: " ";
      position: absolute;
      top: 40%;
      left: -8.5px;
      transform: translate(0, -50%);
      width: 1px;
      height: 8px;
      background-color: ${Color.darkGray};      
    }

    .footer-content .left-content .accent-box li:last-child p {
      margin-top: 0;
    }
  }

  @media screen and (min-width: 768px) {
    .footer-content {
      padding: 50px 0;
    }

    .footer-content .top-content {
      display: flex;
      # gap: 405px;
      gap: 300px;
      justify-content: space-between;
    }

    .footer-content .left-content .footer-logo {
      display: block;
      margin-bottom: 20px;
    }

    .footer-content .left-content ul {
      display: flex;
      flex-direction: row;
      gap: 24px;
      margin-bottom: 10px;
    }

    .footer-content .left-content ul li p {
      display: block;
      font-size: 14px;
      font-weight: 500;
      text-align: left;
      color: ${Color.Black};
    }

    .footer-content .left-content ul li {
      position: relative;
      display: flex;
    }

    .footer-content .left-content ul li:not(:first-child)::after {
      content: " ";
      position: absolute;
      top: 40%;
      left: -12px;
      transform: translate(0, -50%);
      width: 1px;
      height: 8px;
      background-color: ${Color.darkGray};
    }

    .footer-content .left-content .list1 li {
      width: auto;
      white-space: nowrap;
    }
    .footer-content .left-content .list1 li:first-child {
      gap: 24px;
    }

    .footer-content .left-content .list1 li:first-child p:last-child {
      position: relative;
    }

    .footer-content .left-content .list1 li:first-child p:last-child::after {
      content: " ";
      position: absolute;
      top: 40%;
      left: -12px;
      transform: translate(0, -50%);
      width: 1px;
      height: 8px;
      background-color: ${Color.darkGray};
    }

    .footer-content .left-content .list1 li:last-child {
      gap: 24px;
    }

    .footer-content .left-content .list1 li:last-child p:last-child {
      position: relative;
    }

    .footer-content .left-content .list1 li:last-child p:last-child::after {
      content: " ";
      position: absolute;
      top: 40%;
      left: -12px;
      transform: translate(0, -50%);
      width: 1px;
      height: 8px;
      background-color: ${Color.darkGray};
    }

    .footer-content .left-content .accent-box {
      flex-direction: row;
      gap: 24px;
      margin-bottom: 20px;
    }

    .footer-content .left-content .accent-box li p {
      position: relative;
      font-weight: 700;
      color: ${Color.Black};
      width: auto;
    }

    .footer-content .left-content .accent-box li:not(:first-child) p::after {
      content: " ";
      position: absolute;
      top: 40%;
      left: -8.5px;
      transform: translate(0, -50%);
      width: 0;
      height: 0;
      background-color: ${Color.darkGray};
    }

    .footer-content .right-content .link-box {
      display: flex;
      gap: 34px;
      align-items: flex-end;
      height: 100%;
      margin-bottom: 0;
    }

    .footer-content .right-content .link-box .link-blog {
      content: url(${BlogIconDesk});
      margin-bottom: 20px;
    }
    .footer-content .right-content .link-box .link-insta {
      content: url(${InstaIconDesk});
      margin-bottom: 20px;
    }
    .footer-content .right-content .link-box .link-facebook {
      content: url(${FaceBookIconDesk});
      margin-bottom: 20px;
    }

    .footer-content .bottom-content span {
      display: block;
      padding-top: 20px;
      font-size: 14px;
      font-weight: 500;
      color: ${Color.Black};
      border-top: 1px solid ${Color.Gray};
      text-align: left;
    }
  }
`;
