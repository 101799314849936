import { styled } from "styled-components";
import Color from "../../assets/color/color";

export const Main = styled.main`
  padding: 84px 0 100px 0;

  .main-content h2 {
    font-size: 18px;
    font-weight: 700;
    color: ${Color.Black};
    margin-bottom: 15px;
  }

  .main-content .kiosk-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .main-content .kiosk-list .kiosk-card .kiosk-img {
    width: 100%;
    height: 272px;
    margin-bottom: 15px;
  }

  .main-content .kiosk-list .kiosk-card .kiosk-info .kiosk-name {
    width: 100%;
    font-size: 18px;
    font-weight: 700;
    text-align: left;
  }

  .main-content .kiosk-list .kiosk-card .kiosk-info .kiosk-adress {
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    height: 44px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: 10px;
  }

  .main-content .kiosk-list .kiosk-card .kiosk-info .kiosk-time {
    display: flex;
    gap: 10px;
    /* margin-bottom: 10px; */
  }

  .main-content .kiosk-list .kiosk-card .kiosk-info .kiosk-time p:first-child {
    font-size: 14px;
    font-weight: 500;
    color: ${Color.Yellow};
  }

  .main-content .kiosk-list .kiosk-card .kiosk-info .kiosk-time p:last-child {
    font-size: 14px;
    font-weight: 500;
    color: ${Color.middleBlack};
  }

  .main-content .kiosk-list .kiosk-card .kiosk-info a {
    display: block;
    width: 56px;
    height: 27px;
    font-size: 12px;
    font-weight: 500;
    color: ${Color.Yellow};
    text-align: center;
    line-height: 27px;
    border-radius: 5px;
    border: 1px solid ${Color.Yellow};
  }

  /* >= 768px (Desktop) */
  @media screen and (min-width: 768px) {
    padding: 196px 0 200px 0;

    .main-content h2 {
      font-size: 40px;
      font-weight: 700;
      color: ${Color.Black};
      margin-bottom: 40px;
    }

    .main-content .kiosk-list {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      row-gap: 100px;
      column-gap: 30px;
    }

    .main-content .kiosk-list .kiosk-card .kiosk-img {
      width: 100%;
      object-fit: "cover";
      height: 240px;
      margin-bottom: 16px;
    }

    .main-content .kiosk-list .kiosk-card .kiosk-info {
      position: relative;
    }

    .main-content .kiosk-list .kiosk-card .kiosk-info .kiosk-name {
      width: 100%;
      font-size: 28px;
      font-weight: 700;
      text-align: left;
    }

    .main-content .kiosk-list .kiosk-card .kiosk-info .kiosk-adress {
      width: 100%;
      font-size: 24px;
      font-weight: 400;
      text-align: left;
      height: 68px;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      margin-bottom: 10px;
    }

    .main-content .kiosk-list .kiosk-card .kiosk-info .kiosk-time {
      display: flex;
      gap: 10px;
      /* margin-bottom: 10px; */
    }

    .main-content
      .kiosk-list
      .kiosk-card
      .kiosk-info
      .kiosk-time
      p:first-child {
      font-size: 26px;
      font-weight: 500;
      line-height: 52px;
      color: ${Color.Yellow};
    }

    .main-content .kiosk-list .kiosk-card .kiosk-info .kiosk-time p:last-child {
      font-size: 26px;
      font-weight: 500;
      line-height: 52px;
      color: ${Color.middleBlack};
    }

    .main-content .kiosk-list .kiosk-card .kiosk-info a {
      position: absolute;
      top: 123px;
      right: 0;
      display: block;
      width: 98px;
      height: 52px;
      font-size: 26px;
      font-weight: 500;
      color: ${Color.Yellow};
      text-align: center;
      line-height: 52px;
      border-radius: 10px;
      border: 1px solid ${Color.Yellow};
    }
  }
`;
