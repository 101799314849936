const Color = {
    // Black
    Black: '#212121',
    middleBlack: '#3F3F3F',
    lightBlack: '#464646',
    // Gray
    ExtradarkGray: '#6C6C6C',
    darkGray: '#969696', 
    Gray: '#E1E1E1',
    lightGray: '#FAFAFA',
    // 
    Yellow: '#FEC131',
    lightYellow: '#FFF5DD',
    Red: '#FF0707',
    // 
    darkWhite: '#F1F1F1',
    White: '#FFFFFF',
    warmWhite: '#FEFBF6',
    //
    nav_strok: "#DBDBDB"
}

export default Color